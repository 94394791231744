import CatalogHeader from '@common/catalogHeader';
import MobileHeader from '@common/mobileHeader';
import { openInNewTab } from '@components/landing/utils';
import { ROUTE_EDUCATION, TELEGRAM_FOR_BASE } from '@src/constants';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// import { ReactComponent as NotificationIcon } from '@ui/icons/notification.svg';

import "./styles.less";

class EducationBasePage extends Component {

  render() {
    // const { t } = this.props;

    return (
      <main className="education-base-page">
        <div className="wrap">
          <MobileHeader />
          <CatalogHeader parentPageName='Обучение' parentPageLink={ROUTE_EDUCATION} pageName='Базовый курс' withPaySubscription={false} className='!mb-0' />
          <div className='flex flex-col gap-4 w-full'>
            <div className="info-contents">
              <div className="info-content">
                <div className="info__card ">
                  <div className="text-base">
                    Данный тренинг проходит на обучающей платформе.<br />
                    Предобучение стартуют 1-ого числа каждого месяца.<br />
                    Основной поток стартует 10-ого числа каждого месяца.<br />
                    Вы вправе выбрать любой поток.<br />
                    Ссылка придёт вам на почту, за день до начала занятий.<br />
                    Все возникшие вопросы вы можете задать своему куратору.<br />
                    <br />
                    <br />
                    По вопросам, относительно "Базового курса", можно обратиться к сервис-менеджеру:<br />
                    <br />
                    <button className="standart-btn" onClick={() => openInNewTab(TELEGRAM_FOR_BASE)}>
                      <div>
                        Перейти на Телеграмм-канал поддержки для "Базового курса"
                      </div>
                    </button>
                  </div>
                </div>

                {/*<div className="flex flex-col bg-sky-20 gap-2 font-mono">
                  {programs.map((x) => (
                    <div>
                      {x.files.length > 0
                        ? x.files.map((z) => (
                          <div className='flex flex-row gap-4'>
                            {md5(z.fileName)},&nbsp;
                            {z.fileName}
                          </div>
                        ))
                        : null
                      }
                    </div>
                  ))}
                </div>*/}

              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default withTranslation()(
  withRouter(
    EducationBasePage
  )
);
