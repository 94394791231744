import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Avatar from '@common/avatar';

import { ReactComponent as CuratorIcon } from '@ui/icons/curator.svg';
import { ReactComponent as SocialNetTelegramIcon } from '@ui/icons/socialNetTelegram.svg';

import './styles.less';

class Curator extends Component {
  render() {
    const { userInfo, showTelegramLink } = this.props;

    if (!userInfo || userInfo.id === null || userInfo.id === undefined || !userInfo.curator || !userInfo.curator.id) {
      return null;
    }

    const curatorUserInfo = userInfo.curator;

    return (
      <div className="curator">
        <div className="tab-item active">
          <CuratorIcon />
          Куратор
        </div>
        <div className="curator-content">
          <div className="curator-user">
            <div className="curator-user-img">
              <Avatar size={40} user={curatorUserInfo} avatar={{ url_40: curatorUserInfo.avatar }}/>
            </div>
            <div className="curator-user-text">
              {/*<div className="curator-user-status">
                {userData.curator.status}
              </div>*/}
              <div className="curator-user-name">
                {`${curatorUserInfo.last_name} ${curatorUserInfo.first_name}`}
              </div>
            </div>
          </div>
          {showTelegramLink && (
            <div className="curator-socs">
              <button role='link'>
                <SocialNetTelegramIcon link={curatorUserInfo.telegram} />
              </button>
            </div>
          )}
          <div className="curator-mail">
            <span>E-mail:</span>
            <Link to={`mailto:${curatorUserInfo.email}`}>
              {curatorUserInfo.email}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
});

export default connect(mapStateToProps)(
  Curator
);
