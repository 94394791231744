import changeAuthByUser from '@src/helpers/changeAuthByUser';
import store from '@state/store';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Checkbox, Popover, Table } from 'antd';
import Search from 'antd/es/input/Search';
import dayjs from 'dayjs';

import { QuestionCircleOutlined } from '@ant-design/icons';
import CatalogHeader from '@common/catalogHeader';
import MobileHeader from '@common/mobileHeader';
import { DEFAULT_ROLES, ROLE_FILTER } from '@routes/report/userList/constants';
import api from '@services/api';
import { PAGE_CHANGER_STEPS, ROUTE_REPORT } from '@src/constants';
import getTransactionDetailTable from '@src/helpers/getTransactionDetailTable';

import "./styles.less";

class UserListPage extends Component {
  state = {
    userList: [],
    loading: false,
    roles: DEFAULT_ROLES,
    rolesFilter: ROLE_FILTER,
    // date_from: dayjs(`PERIOD_DELTA_PERIOD_1_WEEK`).format(DATE_FORMAT),
    // date_to: dayjs().format(DATE_FORMAT),
    count: 0,
    page: 1,
    pageSize: PAGE_CHANGER_STEPS[0],
    openedInfo: undefined,
    checkCount: DEFAULT_ROLES.length,
    search: {
      id: undefined,
      first_name: undefined,
      last_name: undefined,
      email: undefined,
      phone: undefined,
      telegram: undefined,
    },
  }

  componentDidMount() {
    this.getUsersList();
  }

  getUsersList = async () => {
    const { page, pageSize, roles, search } = this.state;
    this.setState({loading: true});

    const { status, data } = await api.getReportUsersList({
      page: page - 1,
      pageSize,
      roles,
      id: search.id?.toString() ?? undefined,
      first_name: search.first_name?.toString() ?? undefined,
      last_name: search.last_name?.toString() ?? undefined,
      email: search.email?.toString() ?? undefined,
      phone: search.phone?.toString() ?? undefined,
      telegram: search.telegram?.toString() ?? undefined,
    });

    if (status === 200 && data?.data) {
      this.setState({
        userList: data.data ?? [],
        count: data.totalCount ?? 0,
      });
    }

    this.setState({loading: false});
  }

  changePage = (newPage, newPageSize) => {
    const { pageSize } = this.state;

    this.setState({
      pageSize: newPageSize,
      page: Number(pageSize) !== newPageSize ? 1 : newPage,
    }, async () => await this.getUsersList())
  }

  setOpenedInfo = (user_id) => {
    const { openedInfo } = this.state;

    if (openedInfo === user_id) {
      this.setState({ openedInfo: undefined })
    } else {
      this.setState({ openedInfo: user_id })
    }
  }

  onChangeRoles = (checkedValues) => {
    const { timer } = this.state;
    timer && clearTimeout(timer);
    const newTimer = setTimeout(async () => await this.getUsersList(), 1000);
    this.setState({
      roles: checkedValues,
      page: 1,
      timer: newTimer,
      checkCount: checkedValues.length,
    });
  }

  onCheckAllRoles = (value) => {
    const { timer } = this.state;
    const checkAll = value?.target.checked;
    timer && clearTimeout(timer);
    const newTimer = setTimeout(async () => await this.getUsersList(), 1000);
    const roles = checkAll ? ROLE_FILTER.map((x) => x.value) : [];
    this.setState({
      roles,
      page: 1,
      timer: newTimer,
      checkCount: roles.length,
    });
  }

  onSearch = (name, value) => {
    console.log({
      _f: 'UserListPage-onChange-75',
      name,
      value,
    });
    const roles = ROLE_FILTER.map((x) => x.value);
    this.setState((state) => ({
      search: {
        ...state.search,
        [name]: value,
      },
      page: 1,
      roles,
      checkCount: roles.length,
    }), async () => await this.getUsersList());
  }

  authByUser = async (e, userId) => {
    const { userFullInfo, history } = this.props;

    e.preventDefault();

    await changeAuthByUser(store.dispatch, userFullInfo, userId, history);
  }

  render() {
    const { t, userInfo } = this.props;
    const { userList, loading, count, page, pageSize, rolesFilter, roles, checkCount } = this.state;

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        render: (id) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">ID:</div>
            <div className="column-content-block st-table-num column_id">{id}</div>
          </div>
        ),
      },
      {
        title: 'Имя',
        dataIndex: 'first_name',
        key: 'first_name',
        render: (first_name) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Имя:</div>
            <div className="column-content-block st-table-imfo type column_type">{first_name}</div>
          </div>
        ),
      },
      {
        title: 'Фамилия',
        dataIndex: 'last_name',
        key: 'last_name',
        render: (last_name) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Фамилия:</div>
            <div className="column-content-block st-table-imfo type column_type">{last_name}</div>
          </div>
        ),
      },
      {
        title: 'Почта',
        dataIndex: 'email',
        key: 'email',
        render: (email) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Почта:</div>
            <div className="column-content-block st-table-imfo type column_type">{email}</div>
          </div>
        ),
      },
      {
        title: 'Телефон',
        dataIndex: 'phone',
        key: 'phone',
        render: (phone) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Телефон:</div>
            <div className="column-content-block st-table-imfo type column_type">{phone}</div>
          </div>
        ),
      },
      {
        title: 'Телеграмм',
        dataIndex: 'telegram',
        key: 'telegram',
        render: (telegram) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Телеграмм:</div>
            <div className="column-content-block st-table-imfo type column_type">{telegram}</div>
          </div>
        ),
      },
      // {
      //   title: 'Дата регистрации',
      //   dataIndex: 'date_create',
      //   key: 'date_create',
      //   render: (date) => (
      //     <div className="flex flex-row gap-1 column-row-block">
      //       <div className="block lg:hidden column-title-block">Дата регистрации:</div>
      //       <div className="column-content-block st-table-imfo date column_date">{dayjs(date).format('DD.MM.YY')}</div>
      //     </div>
      //   ),
      // },
      // {
      //   title: 'ID Куратора',
      //   dataIndex: 'curator_id',
      //   key: 'curator_id',
      //   render: (curator_id) => (
      //     <div className="flex flex-row gap-1 column-row-block">
      //       <div className="block lg:hidden column-title-block">ID Куратора</div>
      //       <div className="column-content-block st-table-imfo date column_date">{curator_id}</div>
      //     </div>
      //   ),
      // },
      {
        title: 'Дата покупки',
        dataIndex: ['currentBoughtProduct', 'date_update'],
        key: 'currentBoughtProduct.date_update',
        render: (date) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Дата покупки:</div>
            <div className="column-content-block st-table-imfo date column_date">{date ? dayjs(date).format('DD.MM.YY') : '-'}</div>
          </div>
        ),
      },
      {
        title: 'Пакет',
        key: 'currentProduct.name',
        dataIndex: ['currentProduct', 'name'],
        render: (name) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Пакет:</div>
            <div className="column-content-block st-table-imfo date column_date">{name}</div>
          </div>
        ),
      },
      {
        title: '',
        dataIndex: 'id',
        key: `id_moreInfo`,
        render: (id, record) => {
          const content = getTransactionDetailTable(record);

          return (
            <>
              {
                userInfo.id === 1 && (
                  <button onClick={(e) => this.authByUser(e, record.id)}>
                    Login
                  </button>
                )
              }
              {
                content
                  ? (
                    <Popover
                      title="Детальная информация по транзакции"
                      placement='bottomRight'
                      // open={openedInfo === record.id}
                      content={ content }
                    >
                      <div
                        className="st__form-moreInfo"
                        onClick={ () => this.setOpenedInfo(record.id) }
                      >
                        <QuestionCircleOutlined/>
                      </div>
                    </Popover>
                  )
                  : ''
              }
            </>
          );
        },
      },
    ];

    const paginationChanger = {
      onChange: this.changePage,
      current: page,
      total: count,
      pageSize: pageSize,
      size: 'small',
      showSizeChanger: true,
      pageSizeOptions: PAGE_CHANGER_STEPS,
    }

    return (
      <main className="report-user-list-page">
        <div className="wrap">
          <MobileHeader />
          <CatalogHeader
            pageName={t('LEFT_MENU_REPORT/USERS')}
            parentPageName={t('LEFT_MENU_REPORT')}
            parentPageLink={ROUTE_REPORT}
            withPaySubscription={false}
          />
          <div className="flex flex-col gap-1">
            <form className="static__header static__headerm flex flex-col gap-4">
              <div className="static__head-block">
                <div className="flex flex-row gap-1">
                  <div className="ant-checkbox-wrapper pr-2">
                    <span className=""><strong>Пакет :</strong></span>
                  </div>
                  <Checkbox
                    indeterminate={checkCount > 0 && checkCount < ROLE_FILTER.length}
                    checkAll={checkCount === ROLE_FILTER.length}
                    onChange={this.onCheckAllRoles}
                    checked={checkCount === ROLE_FILTER.length}
                  >
                    Все
                  </Checkbox>
                  <Checkbox.Group
                    options={rolesFilter}
                    value={roles}
                    onChange={this.onChangeRoles}
                  />
                </div>
              </div>
              <div className="static__head-block">
                <div className="flex flex-col gap-1">
                  <div className="flex flex-row gap-1 flex-wrap">
                    <div className="">
                      <Search addonBefore="ID:" onSearch={(value) => this.onSearch('id', value)} allowClear />
                    </div>
                    <div className="">
                      <Search addonBefore="Имя:" onSearch={(value) => this.onSearch('first_name', value)} allowClear />
                    </div>
                    <div className="">
                      <Search addonBefore="Фамилия:" onSearch={(value) => this.onSearch('last_name', value)} allowClear />
                    </div>
                  </div>
                  <div className="flex flex-row gap-1 flex-wrap">
                    <div className="">
                      <Search addonBefore="Почта:" onSearch={(value) => this.onSearch('email', value)} allowClear />
                    </div>
                    <div className="">
                      <Search addonBefore="Телефон:" onSearch={(value) => this.onSearch('phone', value)} allowClear />
                    </div>
                    <div className="">
                      <Search addonBefore="Телеграм:" onSearch={(value) => this.onSearch('telegram', value)} allowClear />
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="static__contents !py-4">
              <div className="static__cont">
                <Table
                  dataSource={userList}
                  columns={columns}
                  loading={loading}
                  rowKey={(record) => record.id}
                  rowClassName="st-table-line flex-col lg:flex-row !items-start lg:items-center w-full gap-1 lg:gap-0"
                  pagination={paginationChanger}
                  className='static-table'
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
  userFullInfo: state.user,
});

export default connect(mapStateToProps)(
  withTranslation()(
    withRouter(
      UserListPage
    )
  )
);
