import CatalogHeader from '@common/catalogHeader';
import MobileHeader from '@common/mobileHeader';
import api from '@services/api';
import { ROUTE_AMBASSADORS_CLUB } from '@src/constants';
import withWindowResize from '@src/helpers/withWindowResize';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// import { ReactComponent as NotificationIcon } from '@ui/icons/notification.svg';

import { getFilteredLines, renterTableFirstLine, TypeOfFirstLine } from './constants';
import "./styles.less";

class StructurePage extends Component {
  state = {
    category: TypeOfFirstLine.partners,
    lines: {},
    loading: {},
    shortLevel: 0,
    opened: [],
    filter: TypeOfFirstLine.partners,
    openedInfo: undefined,
  }

  changeLoading = (userId, value) => {
    this.setState((state) => ({
      ...state,
      loading: {
        ...state.loading,
        [userId]: value,
      }
    }));
  }

  loadingFirstLine = async (userId) => {
    this.changeLoading(userId, true);

    const { status, data } = await api.getUserFirstLine(userId)

    if (status === 200 && data) {
      this.setState((state) => ({
        ...state,
        lines: {
          ...state.lines,
          [userId]: data,
        }
      }))
    }

    this.changeLoading(userId, false);
  }

  addOpened = (user_id) => this.setState((state) => ({
    ...state,
    opened: [
      ...state.opened,
      user_id,
    ],
  }));

  removeOpened = (user_id) => this.setState((state) => ({
    ...state,
    opened: state.opened.filter((x) => x !== user_id),
  }));

  setOpenedInfo = (user_id) => {
    const { openedInfo } = this.state;

    if (openedInfo === user_id) {
      this.setState({ openedInfo: undefined })
    } else {
      this.setState({ openedInfo: user_id })
    }
  }

  render() {
    const { t, userId, innerWidth } = this.props;
    const { loading, lines, shortLevel, opened, filter, openedInfo } = this.state;

    const isLgWidth = innerWidth < 768;

    const currentUserLines = !loading[userId] && lines[userId] ? lines[userId] : [];

    return (
      <main className="structure-page">
        <div className="wrap">
          <MobileHeader />
          <CatalogHeader
            pageName={t('LEFT_MENU_AMBASSADORS_CLUB/STRUCTURE')}
            parentPageName={t('LEFT_MENU_AMBASSADORS_CLUB')}
            parentPageLink={ROUTE_AMBASSADORS_CLUB}
            withPaySubscription={false}
          />
          <div className="st__contents">
            <div className="st__top">
              {/*<div className="main-col">
                <Indicators />
              </div>*/}
              <div className="info__card !mt-1">
              {/*<div className="st__p-block">*/}
                <div className="infop__video-nav">
                  {Object.keys(TypeOfFirstLine).map((x) => (
                    <div key={x} className={classNames("info__video-block", filter === x ? 'active' : '')}>
                      <button onClick={() => this.setState({ filter: x })}>
                        {t(`STRUCTURE_TYPE_OF_FIRST_LINE__${x.toUpperCase()}`)} ({getFilteredLines(x, currentUserLines).length})
                      </button>
                    </div>
                  ))}
                </div>
                {renterTableFirstLine(userId, isLgWidth, 1, loading, lines, opened, filter, openedInfo, this.setOpenedInfo, this.loadingFirstLine, this.addOpened, this.removeOpened, shortLevel)}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.user.user_id,
});

export default connect(mapStateToProps)(
  withTranslation()(
    withWindowResize(
      withRouter(
        StructurePage
      )
    )
  )
);
