import Avatar from '@common/avatar';
import Ga2fa from '@common/drawer/ga2fa';
import FormWithMessage from '@common/formWithMessage';
import api from '@services/api';
import { AVATAR_IMAGE_SIZE, AVATAR_IMAGE_SIZE_BYTES, AVATAR_IMAGE_TYPES } from '@src/constants';
import { getMessageFromRequest } from '@src/helpers';
import { closeModal, setModalContent } from '@state/modal';
import store from '@state/store';
import ImgCrop from 'antd-img-crop';
import classNames from 'classnames';
import { countries } from 'countries-list-es5/dist';
import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { Form, Input, message, Select, Spin, Upload } from 'antd';

import { closeDrawer, setDrawerContent } from '@state/drawer';

import { ReactComponent as CloseIcon } from '@ui/icons/close.svg';

import '@routes/login/styles.less';

class Settings extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    textMessage: undefined,
    isSuccess: false,
    isError: false,
    userCountry: 'RU_7',
    maxPhoneLength: 12,
    userPhonePrefix: '+7',
    country: JSON.stringify(countries['RU']),
    countryKey: 'RU',
    avatar: undefined,
  }

  componentDidMount() {
    const { userInfo } = this.props;
    if (userInfo?.country_key) {
      this.setUserCountry(`${userInfo?.country_key}_${userInfo?.prefix.replace('+', '')}`);
    }
  }

  setUserCountry = (val) => {
    const [key, prefix] = val.split('_');
    const currentCountry = JSON.stringify(countries[key]);
    // this.formRef?.current?.setFieldValue('phone', undefined);
    const newPhonePrefix = `+${prefix}`;
    const oldPhone = this.formRef?.current?.getFieldValue('phone');
    const oldPhoneClear = oldPhone.substr(-10, 10);

    this.formRef?.current?.setFieldValue('countryList', val);
    this.formRef?.current?.setFieldValue('countryKey', key);
    this.formRef?.current?.setFieldValue('country', currentCountry);
    this.formRef?.current?.setFieldValue('prefix', newPhonePrefix);
    this.formRef?.current?.setFieldValue('phone', `${newPhonePrefix}${oldPhoneClear}`);

    this.setState({
      userCountry: val,
      userPhonePrefix: newPhonePrefix,
      maxPhoneLength: prefix.length + 11,
      country: currentCountry,
      countryKey: key,
    });
  }

  handleSubmit = (val) => {
    // const { location, userInfo } = this.props;

    this.formRef.current.validateFields().then(async (values) => {
      this.setState({ loading: true });

      // const { status, data } = await api.sendFeedBack({location, userInfo, values});
      const { status, data } = await api.updateUserInfo({
        last_name: values.lastName,
        first_name: values.firstName,
        phone: values.phone,
        telegram: values.telegram,
        country_key: values.countryKey,
        prefix: values.prefix,
        country: JSON.stringify(values.country),
        country_list: values.countryList,
      });

      this.setState({
        loading: false,
        textMessage: getMessageFromRequest(data),
      });

      if (status === 200) {
        this.setState({
          isSuccess: data?.status === 0,
          isError: data?.status !== 0,
        })
      } else {
        this.setState({
          isError: true,
        })
      }
    });
  };

  onDisable2fa = async (event) => {
    const { setCloseModal, updateModalContent } = this.props;
    event.preventDefault();
    event.stopPropagation();

    const content = (
      <>
        Вы действительно хотите отменить 2-х-факторную аутентификацию ?
      </>
    );
    updateModalContent({
      title: 'Подтверждение отмены',
      content,
      params: {
        onOk: async () => {
          const {status, data} = await api.disableUser2fa();

          if (status === 200 && data?.status === 0) {
            message.success("2-х-факторная аутентификация успешно отключена");
            await api.updateUserExtraInfo();
            setCloseModal();
          } else {
            message.error("Ошибка отключения 2-х-факторной аутентификации");
          }
        },
        onCancel: () => {
          setCloseModal();
        },
        okText: "Да, отменить",
        okType: 'default',
        okButtonProps: {
          shape: 'round',
          danger: true,
        },
        cancelText: "Нет, не отменять",
        cancelButtonProps: {
          shape: 'round',
          type: 'primary',
        },
      },
    });
  }

  onEnable2fa = async (event) => {
    const { updateDrawerContent } = this.props;

    event.preventDefault();
    event.stopPropagation();

    updateDrawerContent({
      content: Ga2fa,
    })
  }

  sendfileCrop = async (file) => {
    const formDataPrime = new FormData();

    formDataPrime.append('file_crop', file);

    const { status, data } = await api.uploadUserAvatar(formDataPrime);

    if (status === 200 && data.status === 0) {
      this.setState({ avatar: data.avatar, loading: false });
      await api.updateUserExtraInfo();
    } else {
      message.error(data.message);
      this.setState({ avatar: undefined, loading: false });
    }
  }

  beforeCrop = (file) => {
    if (file && !AVATAR_IMAGE_TYPES.split(', ').includes(file.type)) {
      message.error("Загрузка отменена. К сожалению мы не можем загрузить файл данного типа", 5)
      return false;
    }

    if (file && file.size > AVATAR_IMAGE_SIZE_BYTES) {
      message.error(`Загрузка отменена. К сожалению мы не можем загрузить файл размером более ${AVATAR_IMAGE_SIZE} Мб`, 5)
      return false;
    }

    this.setState({ loading: true })
    return true;
  }

  deleteAvatar = async () => {
    store.dispatch(setModalContent({
      title: 'Подтверждение удаления аватарки',
      content: "Вы действительно хотите удалить аватарку?",
      params: {
        onOk: async () => {
          this.setState({ loading: true });
          store.dispatch(closeModal());

          const { status, data } = await api.deleteUserAvatar();

          if (status === 200 && data.status === 0) {
            this.setState({ avatar: null, loading: false });
            await api.updateUserExtraInfo();
          } else {
            message.error(data.message);
            this.setState({ loading: false });
          }
        },
        okType: 'default',
        okButtonProps: {
          shape: 'round',
        },
        cancelButtonProps: {
          shape: 'round',
        },
      },
    }));
  }

  render() {
    const { userInfo, setCloseDrawer } = this.props;
    const {
      loading, isError, isSuccess, textMessage, userCountry, userPhonePrefix,
      maxPhoneLength, country, countryKey, avatar,
    } = this.state;

    const countryList = [];
    Object
      .keys(countries)
      .forEach((key) => {
        const phones = countries[key].phone.split(',');
        phones.forEach((phone) => {
          countryList.push({
            value: `${key}_${phone}`,
            label: `${countries[key].name} (Phone prefix: +${phone})`,
          });
        });
      });

    const userAvatar = avatar || userInfo.avatar;
    const hasAvatar = userAvatar && Object.keys(userAvatar).length > 0;

    return (
      <div id="modal4" className="modal4 feedback-modal modal settings" key={userInfo.id}>
        <Spin spinning={loading}>
          <div className="modal__block-close">
            <CloseIcon onClick={setCloseDrawer} />
          </div>
          <div className="modal__window">
            <div className="modal__head">
              <h1>Настройки</h1>
            </div>
            <div className="flex flex-row mt-4 gap-8 align-baseline justify-center">
              <Avatar
                avatar={avatar}
                size={110}
              />
              <div className="flex flex-col gap-4 align-baseline justify-center">
                <div>
                  <ImgCrop
                    cropShape="round"
                    zoomSlider
                    modalTitle="Загрузка аватарки пользователя"
                    onModalOk={this.sendfileCrop}
                    onModalCancel={() => this.setState({ loading: false })}
                    beforeCrop={this.beforeCrop}
                  >
                  <Upload
                    accept={AVATAR_IMAGE_TYPES}
                    type='select'
                    action={() => Promise.reject()}
                    listType="text"
                    maxCount={1}
                    showUploadList={false}
                  >
                    <button className="standart-btn !my-0 standart-btn-border">
                      <div className="!px-6 !py-3">
                        {hasAvatar
                          ? 'Изменить изображение'
                          : 'Добавить изображение'
                        }
                      </div>
                    </button>
                  </Upload>
                </ImgCrop>
                </div>
                {hasAvatar && (
                  <div>
                    <button className="standart-btn !my-0 standart-btn-border" onClick={this.deleteAvatar}>
                      <div className="!px-6 !py-3">Удалить изображение</div>
                    </button>
                  </div>
                )}
              </div>
            </div>
            <hr className='my-4' />
            <button
              className={classNames("standart-btn !my-4", userInfo.ga2fa ? 'standart-btn-border' : null)}
              onClick={userInfo.ga2fa ? this.onDisable2fa : this.onEnable2fa}
            >
              <div>
                {userInfo.ga2fa ? 'Отключить 2-x-факторную аутентификацию' : 'Подключить 2-x-факторную аутентификацию'}
              </div>
            </button>
            <hr className='my-4' />
            <FormWithMessage
              setFormType={() => {}}
              isError={isError}
              isSuccess={isSuccess}
              textMessage={textMessage}
              onClickButtonGoHome={() => setCloseDrawer()}
            >
              <Form
                name='SettingsForm'
                ref={this.formRef}
                layout='vertical'
                initialValues={{
                  firstName: userInfo.first_name,
                  lastName: userInfo.last_name,
                  phone: userInfo.phone,
                  telegram: userInfo.telegram,
                  country: userInfo.country?.length > 0 ? userInfo.country : country,
                  countryKey: userInfo.country_key?.length > 0 ? userInfo.country_key : countryKey,
                  countryList: userCountry,
                  prefix: userInfo.prefix?.length > 0 ? userInfo.prefix : userPhonePrefix,
                }}
                onFinish={this.handleSubmit}
                // className={styles.content}
              >
                <div className="form__content">
                  <Form.Item name='lastName' label="Фамилия" className="form__block"
                             validateTrigger={['onBlur', 'onChange']}
                             validateFirst={true}
                             rules={[
                               {required: true, message: 'Пожалуйста, введите свою фамилию'},
                               {max: 100, message: 'Максимальная длина поля - 100 символов'},
                               {transform: (x) => x.trim(), pattern: /^[a-zA-Z ]+$/, message: 'В этом поле можно вводить только буквы (латиница) и пробел'},
                             ]}>
                    <Input placeholder='Ivanov'/>
                  </Form.Item>
                  <Form.Item name='firstName' label="Имя" className="form__block"
                             validateTrigger={['onBlur', 'onChange']}
                             validateFirst={true}
                             rules={[
                               {required: true, message: 'Пожалуйста, введите своё имя'},
                               {max: 100, message: 'Максимальная длина поля - 100 символов'},
                               {transform: (x) => x.trim(), pattern: /^[a-zA-Z ]+$/, message: 'В этом поле можно вводить только буквы (латиница) и пробел'},
                             ]}>
                    <Input placeholder='Ivan'/>
                  </Form.Item>
                  <Form.Item name='countryList' label="Страна" className="form__block">
                    <Select
                      showSearch
                      options={countryList}
                      onSelect={this.setUserCountry}
                      getPopupContainer={(node) => node}
                      popupClassName="country-list-drop-down"
                      placeholder="Начните вводить страну на родном или Английском языке"
                      filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                    />
                  </Form.Item>
                  <Form.Item name='country' hidden={true}><Input /></Form.Item>
                  <Form.Item name='countryKey' hidden={true}><Input /></Form.Item>
                  <Form.Item name='prefix' hidden={true}><Input /></Form.Item>
                  <Form.Item name='phone' label="Номер телефона" className="form__block"
                             validateTrigger={['onBlur', 'onChange']}
                             validateFirst={true}
                             rules={[
                               {required: true, message: 'Пожалуйста, введите свой номер телефона'},
                               {transform: (x) => x.replace(/[^+\d]/, '').replace(/[() -]/g, ''), max: maxPhoneLength, message: 'Максимально можно ввести 10 цифр, не включая код страны и знак "плюс" {value}'},
                               {pattern: /^\+/, message: 'Первым должен идти знак "плюс" и код страны (+7 для России)'},
                               {pattern: /^[+\d() -]*/, message: 'В этом поле можно вводить только цифры, круглые скобки, дефисы, пробелы и код страны (+7 для России)'},
                               {
                                 validateTrigger: 'onBlur',
                                 transform: (x) => x.replace(/[^\d+]/, '').replace(/[() -]/g,''),
                                 pattern: new RegExp('^\\+\\d{'.concat((maxPhoneLength - 1).toString(), '}$')),
                                 message: 'Номер телефона должен состоять из 10 цифр (не включая знак "плюс", код страны, скобки, дефисы и пробелы)',
                               },
                             ]}>
                    <InputMask
                      key={userPhonePrefix}
                      mask={`${userPhonePrefix.replace(/9/g,'\\9')} (999) 999-99-99`}
                      maskChar="_"
                      alwaysShowMask={true}
                    />
                  </Form.Item>
                  <Form.Item name='telegram' label="Имя пользователя в Telegram" className="form__block"
                             validateTrigger={['onBlur', 'onChange']}
                             validateFirst={true}
                             rules={[
                               {required: true, message: 'Пожалуйста, введите своё имя пользователя в Телеграмм'},
                               {pattern: /^@/, message: 'Имя должно начитаться с "собачки" (@)'},
                               {pattern: /^(@|@[a-zA-Z0-9_]+)$/, message: 'Имя должно содержать только символы a-z, 0-9 или знак подчеркивания'},
                               {validateTrigger: 'onBlur', min: 6, message: 'Имя должно начитаться с "собачки" (@), далее минимум 5 символов'},
                               {validateTrigger: 'onBlur', pattern: /@[a-zA-Z0-9_]{5,}/, message: 'Имя должно начитаться с "собачки" (@), далее минимум 5 символов a-z, 0-9 или знак подчеркивания'},
                             ]}>
                    <Input placeholder='@nickName' autoComplete='off' name='telegram' id='telegram'/>
                  </Form.Item>
                </div>
                <button className="standart-btn">
                  <div>Сохранить</div>
                </button>
              </Form>
            </FormWithMessage>
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
  drawer: state.drawer,
});

const mapDispatchToProps = {
  setCloseDrawer: closeDrawer,
  setCloseModal: closeModal,
  updateDrawerContent: setDrawerContent,
  updateModalContent: setModalContent,
}

export default connect(mapStateToProps, mapDispatchToProps)(
  Settings
);
