import storage from '@services/storage';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { LoginForm, RegistrationForm } from '@routes/login/form';
import NewPasswordForm from '@routes/login/form/newPassword';
import ResetPasswordForm from '@routes/login/form/resetPassword';
import VerifyEmailForm from '@routes/login/form/verifyEmail';
import VerifyEmailRepeatForm from '@routes/login/form/verifyEmailRepeat';
import VerifyResetPassword from '@routes/login/form/verifyResetPassword';
import {
  FORM_REGISTRATION,
  FORM_VERIFY_EMAIL_REPEAT,
  FORM_RESET_PASSWORD,
  FORM_VERIFY_EMAIL,
  FORM_NEW_PASSWORD,
  FORM_VERIFY_RESET_PASSWORD, STORAGE_REGISTER_CURATOR_ID, FORM_LOGIN,
} from '@src/constants';

import './styles.less';

const LoginRoute = ({ formType, setFormType }) => {
  const { cur_id } = useParams();

  if (cur_id && Number(cur_id)) {
    storage.set(STORAGE_REGISTER_CURATOR_ID, Number(cur_id));
  }

  const formRef = useMemo(() => React.createRef(), [])

  const [title, FormName] = useMemo(() => {
    let titleText;
    let FormComponent;
    switch(formType) {
      case FORM_LOGIN:
        FormComponent = LoginForm;
        titleText = (
          <div className="modal__head">
            <h1>Авторизация</h1>
          </div>
        );
        break;
      case FORM_REGISTRATION:
        FormComponent = RegistrationForm;
        titleText = (
          <div className="modal__head">
            <h1>Регистрация</h1>
          </div>
        );
        break;
      case FORM_VERIFY_EMAIL:
        FormComponent = VerifyEmailForm;
        titleText = (
          <div className="modal__head">
            <h1>Подтверждение регистрации</h1>
          </div>
        );
        break;
      case FORM_VERIFY_EMAIL_REPEAT:
        FormComponent = VerifyEmailRepeatForm;
        titleText = (
          <div className="modal__head">
            <h1>Повторное подтверждение регистрации</h1>
          </div>
        );
        break;
      case FORM_RESET_PASSWORD:
        FormComponent = ResetPasswordForm;
        titleText = (
          <div className="modal__head">
            <h1>Восстановление пароля</h1>
          </div>
        );
        break;
      case FORM_VERIFY_RESET_PASSWORD:
        FormComponent = VerifyResetPassword;
        titleText = (
          <div className="modal__head">
            <h1>Восстановление пароля</h1>
          </div>
        );
        break;
      case FORM_NEW_PASSWORD:
        FormComponent = NewPasswordForm;
        titleText = (
          <div className="modal__head">
            <h1>Замена пароля</h1>
          </div>
        );
        break;
      // case FORM_FEEDBACK:
      //   FormComponent = FeedBackForm;
      //   titleText = (
      //     <div className="modal__head">
      //       <h1>Заменить пароль</h1>
      //     </div>
      //   );
      //   break;
      default:
    }
    return [titleText, FormComponent];
  }, [formType]);

  return formType
    ? (
      <div className="ant-modal-root">
        <div className="ant-modal-mask"></div>
        <div className="ant-modal-wrap" onClick={() => setFormType()}>
          <div
            role="dialog"
            aria-modal="true"
            className="ant-modal modal dialog"
          >
            <div className="ant-modal-content" onClick={(e) => e.stopPropagation()}>
              <div className="ant-modal-header">
                {title}
              </div>
              <div className="ant-modal-body">
                <div className="modal__window">
                  <FormName setFormType={setFormType} formRef={formRef} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
    : null;
};

export default LoginRoute;
