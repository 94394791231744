export const ROLE_FILTER = [
  {'value': '0', 'label': 'Без пакета'},
  {'value': '1', 'label': 'Базовый курс'},
  {'value': '2', 'label': 'Академия'},
  {'value': '3', 'label': 'Партнер'},
  {'value': '5', 'label': 'Тест-Драйв'},
  {'value': '4', 'label': 'Амбассадор'},
]

export const DEFAULT_ROLES = ['4', '5'];
