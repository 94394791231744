import InfoItem from '@common/infoItem';
import MoneyStatistic from '@common/moneyStatistic';
import { LEVEL_GRAND_AMBASSADOR, ROLE_AMBASSADOR, ROLE_LIST_FOR_BUSINESS } from '@src/constants';
import { disableAccessToIndicatorsPage } from '@src/helpers/checkAccessToElementPage';
import { useAppSelector } from '@state/store';
import classNames from 'classnames';
import React, { FC, useMemo } from 'react';

import Curator from '@common/curator';
import Hello from '@common/hello';
import Indicators from '@common/indicators';
import MobileHeader from '@common/mobileHeader';
import Programs from '@common/programs';
import ShortStatistics from '@common/shortStatistics';

import "./styles.less";

const Root: FC = () => {
  const userInfo = useAppSelector((state) => state.user.info);
  const indicators = !disableAccessToIndicatorsPage(userInfo);

  const funds = useMemo(
    () => userInfo
      ?.company_funds
      ?.reduce(
        (obj: Record<string, number>, el) => {
          obj[el.code] = el.balance || 0;
          return obj;
        },
        {}
      ) || {},
    [userInfo?.company_funds]
  );

  const enableMoneyStatistic = useMemo(
    () => ROLE_LIST_FOR_BUSINESS.includes(userInfo?.role_id ?? -999)
    , [userInfo?.role_id]
  );
  const enableFunds = useMemo(
    () => userInfo?.role_id === ROLE_AMBASSADOR
    , [userInfo?.role_id]
  );
  const enableStabFund = useMemo(
    () => enableFunds && Number(userInfo?.level || 0) >= LEVEL_GRAND_AMBASSADOR
    , [enableFunds, userInfo?.level]
  );
  const enableEvolutionFund = useMemo(
    () => [-1, 0, 1, 2, 3, 4].includes(userInfo?.id ?? -999)
    , [userInfo?.id]
  );

  return (
    <main>
      <div className="wrap">
        <MobileHeader />
        <Hello />
        <Programs />

        <div className="main-content root">
          {indicators && (
            <div className="main-col">
              <Indicators />
            </div>
          )}

          <div className={indicators ? "main-col main-col-big" : "main-col-full"}>
            <ShortStatistics
              showIncome={false}
            />
            {enableMoneyStatistic && (
              <MoneyStatistic />
            )}
            <div className={classNames("info gap-4 flex flex-row root", enableFunds ? '' : '!hidden')}>
              <InfoItem
                active={funds?.['QUARTER_TRC_20'] > 0}
                value={Number(funds?.['QUARTER_TRC_20'] || 0).toFixed(1)}
                title={["Дивидендный фонд", "(текущий баланс)"]}
                // white={true}
                className={'fund-green'}
              />
              <InfoItem
                active={funds?.['STABLE_FOND_TRC_20'] > 0}
                value={Number(funds?.['STABLE_FOND_TRC_20'] || 0).toFixed(1)}
                title={["Стабилизационный фонд", "(текущий баланс)"]}
                // white={true}
                className={classNames('fund-yellow', enableStabFund ? '' : '!hidden')}
              />
              {enableEvolutionFund
                ? (
                  <InfoItem
                    active={funds?.['EVOLUTION_TRC_20'] > 0}
                    value={Number(funds?.['EVOLUTION_TRC_20'] || 0).toFixed(1)}
                    title={["Фонд развития", "(текущий баланс)"]}
                    // white={true}
                    className='fund-cian'
                  />
                )
                : (
                  <InfoItem
                    active={userInfo?.isSubscribe}
                    value={userInfo?.isSubscribe ? "Да" : "Нет"}
                    title={["Право на", "вознаграждение",]}
                    white={true}
                  />
                )
              }
            </div>
            <Curator showTelegramLink={false} />
          </div>

        </div>
      </div>
    </main>
  );
}

export default Root;
