import LoginLayout from '@layouts/login';
import MainLayout from '@layouts/main';
import Initial from '@routes/initial';
import {
  APPSTAGE_AUTH,
  APPSTAGE_INITIAL,
  APPSTAGE_SIGNIN, FORM_LOGIN,
  FORM_REGISTRATION,
  FORM_VERIFY_EMAIL,
  FORM_VERIFY_RESET_PASSWORD, ROUTE_LOGIN,
  ROUTE_REGISTRATION,
  ROUTE_REGISTRATION_WITH_ID,
  ROUTE_ROOT,
  ROUTE_VERIFY_EMAIL,
  ROUTE_VERIFY_RESET_PASSWORD,
  ROUTE_VERIFY_UPDATE_USER_INFO,
  ROUTE_VERIFY_WITHDRAWAL,
} from '@src/constants';
import { useAppSelector } from '@state/store';
import React, { FC, memo, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';

import './antd.less';
import './app.less';

const App: FC = () => {
  const appStage = useAppSelector(state => state.appStage.stage)

  // message.config({
  //   top: 50,
  //   maxCount: 3,
  // });

  return useMemo(() => {
    switch (appStage) {
      case APPSTAGE_INITIAL:
        return <Initial />;
      case APPSTAGE_AUTH:
        return <MainLayout />;
      case APPSTAGE_SIGNIN:
        // return <LoginLayout />;
        return (
          <Switch>
            <Route path={ROUTE_REGISTRATION_WITH_ID} render={() => <LoginLayout />} />
            <Route path={ROUTE_REGISTRATION} render={() => <LoginLayout form={FORM_REGISTRATION} />} />
            <Route path={ROUTE_LOGIN} render={() => <LoginLayout form={FORM_LOGIN} />} />
            <Route path={ROUTE_VERIFY_EMAIL} render={() => <LoginLayout form={FORM_VERIFY_EMAIL} />} />
            <Route path={ROUTE_VERIFY_RESET_PASSWORD} render={() => <LoginLayout form={FORM_VERIFY_RESET_PASSWORD} />} />
            <Route path={ROUTE_VERIFY_WITHDRAWAL} render={() => <LoginLayout form={FORM_LOGIN} />} />
            <Route path={ROUTE_VERIFY_UPDATE_USER_INFO} render={() => <LoginLayout form={FORM_LOGIN} />} />
            <Route path={ROUTE_ROOT} render={() => <LoginLayout />} />
          </Switch>
        );
      default:
        return <></>;
    }
  }, [appStage]);
}

export default memo(App);
