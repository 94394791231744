import { RootState } from '@state/store';
import { IAvatarList, IUserInfo } from '@state/user';
import classNames from 'classnames';
import React, { FC } from 'react';
import { connect } from 'react-redux';

import './styles.less';

export interface IUserModel {
  id: number | null,
  email: string,
  first_name: string,
  last_name: string,
  phone: string,
  telegram: string,
  avatar: IAvatarList | null,
  curator_id: number | null,
  role_id: number | null,
  status_id: number;
  currency_id: number;
  create_from_company: number;
  level: number;
  pv: number;
  nv: number;
  av: number;
  status_name: string;
  role_name: string;
  firstLineCount: number;
  product?: string;
}

interface OwnProps {
  size: number | string;
  userInfo?: IUserInfo;
  user?: IUserModel;
  className?: string;
  avatar?: IAvatarList;
}

const Avatar: FC<OwnProps> = ({
  size,
  user,
  userInfo,
  className,
  avatar,
}) => {
  const currentUser = user || userInfo;
  if (!currentUser || currentUser.id === null || currentUser.id === undefined) {
    return null;
  }

  const numberSize = typeof size === 'number'
    ? size
    : Number(size.replace(/^(\d+)\D*$/, '$1'));

  const sizeInPx     = `${ numberSize }px`;
  const fontSizeInPx = `${ numberSize / 3 }px`;

  const currentAvatar = avatar || currentUser.avatar;

  let src = '';
  currentAvatar && [40,80,110]
    .filter((x) => x >= numberSize)
    .reduce((result, el) => {
      if (result) return result;
      const key = `url_${el}`;
      src = currentAvatar?.[key as keyof IAvatarList] || '';
      return src.length;
    }, 0)
  const srcData = src?.length && process.env.REACT_APP_CDN_AVATAR?.length && process.env.REACT_APP_CDN_AVATAR.concat(src);

  return (
    currentAvatar && srcData
      ? (
        <div className={classNames('avatar', className)} style={{ height: sizeInPx, maxHeight: sizeInPx, width: sizeInPx, minWidth: sizeInPx }}>
          <img src={srcData} alt="user avatar" style={{ height: sizeInPx, width: sizeInPx }} />
        </div>
      )
      : (
        <div className={classNames('empty_avatar', className)} style={{ height: sizeInPx, width: sizeInPx, minWidth: sizeInPx }}>
          <div className="ellipse" style={{ height: sizeInPx, width: sizeInPx }} />
          <div className='empty_avatar_text' style={{ fontSize: fontSizeInPx }}>
            {currentUser.first_name[0]}{currentUser.last_name[0]}
          </div>
        </div>
      )
  );
}

const mapStateToProps = (state: RootState) => ({
  userInfo: state.user.info,
});

export default connect(mapStateToProps)(
  Avatar
);
