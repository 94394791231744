import { openInNewTab } from '@components/landing/utils';
import {
  ROLE_BEGINNER,
  ROLE_STUDENT_ACADEMY,
  ROLE_STUDENT_ACADEMY_PLUS,
  ROLE_STUDENT_BASE,
  ROLE_STUDENT_BASE_PLUS,
  // ROUTE_CONSULTANT,
  ROUTE_EDUCATION_BASE,
  ROUTE_EDUCATION_CRYPTO_TRADING,
  ROUTE_EDUCATION_DEFI,
  // ROUTE_EDUCATION_KNOWLEDGE,
  ROUTE_EDUCATION_NFT,
  ROUTE_EDUCATION_PRESALE,
  ROUTE_UNIVERSITY_CONSULTANT,
  TELEGRAM_FOR_CRYPTO,
  TELEGRAM_FOR_DEFI,
  TELEGRAM_FOR_NFT,
  TELEGRAM_FOR_PRESALE,
} from '@src/constants';

// import { ReactComponent as BaseIcon } from '@ui/bg/catalog-base-bg.svg';
// import { ReactComponent as AcademyIcon } from '@ui/bg/catalog-academy-bg.svg';
import { Link } from 'react-router-dom';

export const programs_category = [
  {idx: 1, code: "base_cr", title: "Базовый курс",    background: <div className='academy-img base'>&nbsp;</div>   , className: 'partner_file partner_academy'},
  {idx: 2, code: "game_fi", title: "Крипто-Академия", background: <div className='academy-img academy'>&nbsp;</div>, className: 'partner_t academy_t'},
];

export const programs = [
  {programs_category: 1, order: 1, fileImageNumber: 1, code: "base_cr", title: "Базовый курс",      exclude_roles: [ROLE_BEGINNER], linkPath: ROUTE_EDUCATION_BASE, files: []},
  // {programs_category: 2, order: 2, fileImageNumber: 3, code: "base_kn", title: "База знаний",       exclude_roles: [ROLE_BEGINNER, ROLE_STUDENT_BASE], linkPath: ROUTE_EDUCATION_KNOWLEDGE, files: []},
  {programs_category: 2, order: 3, fileImageNumber: 4, code: "p_sale",  title: "Presale",           exclude_roles: [ROLE_BEGINNER, ROLE_STUDENT_BASE, ROLE_STUDENT_BASE_PLUS], linkPath: ROUTE_EDUCATION_PRESALE,
    files: [
      {hls: true, url: "8b1f32b9a4afc825671fefe7b321555778c75088dfed9a03653acf2d0d1c8258", order: 1, type: 'video', duration: '0:04:35', fileName: 'ICO_IDO.lesson_1.Intro.m4v', title: 'Урок 1. Введение.'},
      {hls: true, url: "ff1c673a0de686f6a11e1a836d8d54e8146fd115cbc2727fee38221f1710e989", order: 2, type: 'video', duration: '0:09:01', fileName: 'ICO_IDO.lesson_2.Terms.m4v', title: 'Урок 2. Термины и определения.'},
      {hls: true, url: "d87a3bf51f74ee6a2859a75dbdae8e9639a80f201be04fdc87c210c3f75eae68", order: 3, type: 'video', duration: '0:05:15', fileName: 'ICO_IDO.lesson_3.Polkastarter.m4v', title: 'Урок 3. Проект ПолькаСтартер'},
      {hls: true, url: "199ddc33fda8c37cccdd72e31cc7cf9e3518334890ab7475882dfcf65f634c8a", order: 4, type: 'video', duration: '0:04:20', fileName: 'ICO_IDO.lesson_4.Coinlist.m4v', title: 'Урок 4. Проект КоинList',
        description: <div>Инструкция по регистрации и верификации на сайте CoinList можно скачать на вкладке "Книги"</div>,
      },
      {hls: true, url: "3b7e1275351e7e8d3fde4ec56522c217dbd180ff700bf80a1447761f5c33ca38", order: 5, type: 'video', duration: '0:17:23', fileName: 'ICO_IDO.lesson_5.Mini.m4v', title: 'Урок 5. Мини-курс'},

      // {order: 1, type: 'audio', duration: '0:04:35', url: 'https://storage.googleapis.com/media-session/elephants-dream/the-wires.mp3', ext: 'mp3', title: 'Тестовое аудио.'},                // ICO_IDO.lesson_1.Intro.m4v

      {order: 1, type: 'book', fileName: 'ICO_IDO.CoinList.Registration_and_verification_(instructions).docx', ext: 'docx', size: 1853915, title: 'Инструкция по регистрации и верификации на сайте CoinList'},

      {hls: true, url: "5217e52f630662d362b6a189f3c64abc086c362dc660a0a1bf74f5bd7c20e0de", order: 1, type: 'webinar', duration: '0:19:49', fileName: 'ICO_IDO.Maiar.m4v', title: 'Площадка "Maiar launchbar"',
        description: (
          <>
            <div>Кошелек тут: <Link className="underline" to={{ pathname: 'https://maiar.com/'}} target='_blank'>https://maiar.com/</Link></div>
            <div>Лаунчпад тут: <Link className="underline" to={{ pathname: 'https://maiarlaunchpad.com/'}} target='_blank'>https://maiarlaunchpad.com/</Link></div>
            <div>Стейкаем тут: <Link className="underline" to={{ pathname: 'https://wallet.elrond.com/unlock/keys'}} target='_blank'>https://wallet.elrond.com/unlock/keys...</Link></div>
          </>
        ),
      },
      {hls: true, url: "656b3733e2f7c2ede3d2b40795e1b23bffe90691d8e729b2233b3a93b8c5a756", order: 2, type: 'webinar', duration: '0:15:10', fileName: 'ICO_IDO.MarsDao.m4v', title: 'Проект "Mars Dao"'},
      {hls: true, url: "3d6c20baba9264713b6b661712c74c63b8ac68451a0f6573a0659ce681b69ac6", order: 3, type: 'webinar', duration: '0:27:14', fileName: 'ICO_IDO.Multi_account.m4v', title: 'Мульти-аккаунт'},
      {hls: true, url: "214972eaa324fd291407950976b4f939f97fea8a7ce2fed686c69b5cfd636ab8", order: 4, type: 'webinar', duration: '0:26:44', fileName: 'ICO_IDO.CryptoGPT_on_DaoMaker.m4v', title: 'Проект "CryptoGPT" на площадке "DAO Maker"'},
    ],
    description: (
      <div>
        <div>Pre-ICO – это мероприятие по продаже токенов, которое проводят предприятия Blockchain до начала официальной кампании краудсейла или ICO. Цели сбора средств для Pre-ICO часто ниже, чем у основных ICO, и токены обычно продаются дешевле. Это и является темой нашего факультета, который вас во всем обучит и поможет</div>
        <div>После окончания курса вы:</div>
        <ul>
          <li>Узнаете совершенно новые для себя понятия в мире Presale</li>
          <li>Научитесь разбираться в том, в какие проекты нужно заходить, как определить лучший и как заработать в этой сфере</li>
          <li>Откроете для себя более широкий кругозор в области криптовалюты</li>
          <li>Сможете начать зарабатывать свои первые деньги на Presale</li>
          <li>Сумеете различать IDO от ICO применив это в практике</li>
        </ul>
        <div>Это только пару десятков процентов от всего материала, который вы получите и изучите благодаря нашему наставнику</div>
        <div>&nbsp;</div>
        <button className="standart-btn" onClick={() => openInNewTab(TELEGRAM_FOR_PRESALE)}>
          <div>
            Перейти на Телеграмм-канал факультета "Presale"
          </div>
        </button>
      </div>
    )
  },
  // {programs_category: 2, order: 4, fileImageNumber: 5, code: "nft",     title: "NFT",               exclude_roles: [ROLE_BEGINNER, ROLE_STUDENT_BASE], linkPath: ROUTE_EDUCATION_NFT, files: []},
  {programs_category: 2, order: 5, fileImageNumber: 6, code: "de_fi",   title: "DeFi",              exclude_roles: [ROLE_BEGINNER, ROLE_STUDENT_BASE, ROLE_STUDENT_BASE_PLUS], linkPath: ROUTE_EDUCATION_DEFI,
    files: [
      {hls: true, url: '0b4c1da32850fb67f0ddac7ba73adc83d0cea6a2a4fae502e14876af82f59178', order: 1, type: 'video', duration: '0:09:36', fileName: 'DEFI.lesson_1.Intro.m4v', title: 'Урок 1. Введение.'},
      {hls: true, url: '54f4139a4e7fa88751310745b76fb6408a9d666abdb7141b96a9a4d7c5cdc99f', order: 2, type: 'video', duration: '0:04:46', fileName: 'DEFI.lesson_2.Bitcoin_and_blockchain.m4v', title: 'Урок 2. Биткоины и блокчейн'},
      {hls: true, url: '249278d21edfebc4fb2970998d059f961cde329227f7f9639f3d6f51555507e5', order: 3, type: 'video', duration: '0:10:57', fileName: 'DEFI.lesson_3.Web_3_0_and_ethereum_and_smart-contract.m4v', title: 'Урок 3. WEB 3.0, Эфир (Ethereum) и смарт-контракты'},
      {hls: true, url: 'fdd18c1a517f1c45a2c312729638b4b09b5c2ed31e8e0a8e3c5ee28edacc1e99', order: 4, type: 'video', duration: '0:07:08', fileName: 'DEFI.lesson_4.Main_categories.tokens.m4v', title: 'Урок 4. Ключевые категории DeFi. Токены.'},
      {hls: true, url: 'bb44c151399344e8cd055d611c918ce4b5ae1cab6532ae2e8dc99f3a370d2160', order: 5, type: 'video', duration: '0:11:53', fileName: 'DEFI.lesson_5.Network_standards.Tokenomics.m4v', title: 'Урок 5. Стандарты сетей. Токеномика.'},
      {hls: true, url: 'ba9d92f024d08a1b745ca74a0e6da7710ccf94157097a37c9e02db0549a347f0', order: 6, type: 'video', duration: '0:15:14', fileName: 'DEFI.lesson_6.Ecosystems.m4v', title: 'Урок 6. Экосистема DeFi.'},

      {hls: true, url: "b618169aad1402d4b4b17740902174a166891eed2c859073b1c6e6ddaadc6ee6", order: 1,  type: 'webinar', duration: '0:11:05', fileName: 'DEFI.TrustWallet.m4v', title: 'Обзор кошелька TrustWallet.'},
      {hls: true, url: "1d5aa8c72c545347d39f3f3b2f00f1abac2da3b206642e0029b436c574952ee7", order: 2,  type: 'webinar', duration: '0:15:52', fileName: 'DEFI.About_Airdrop_and_Testnet.m4v', title: 'Что такое Airdrop и Testnet.'},
      {hls: true, url: "6e546e12dbe87900eb7c3a8684a59c134af070507dea9a85a4a7bedc18ea96f4", order: 3,  type: 'webinar', duration: '0:15:52', fileName: 'DEFI.Crypto_NTF_domain.m4v', title: 'Крипто домены. NFT Домены. Для чего и как купить. Перспективы.'},
      {hls: true, url: "2fed1596a1b4492aa9882b946933507692792e5213b2f9bea22251de57e1b50e", order: 4,  type: 'webinar', duration: '0:18:30', fileName: 'DEFI.Decentralized_exchanges.m4v', title: 'Децентрализованные биржи'},
      {hls: true, url: "426f77a2ca50b0867a3aae691ccdfe2a4245d5579bb6ce74fc106bd11e9b5c97", order: 5,  type: 'webinar', duration: '0:16:28', fileName: 'DEFI.Risks_and_problems_when_working_with_DEX.m4v', title: 'Риски и проблемы при работе с DEX'},
      {hls: true, url: "507abf87f7753f757b66ce4f8373b223b644a61fa8d34f0e1eca84954b68ee1a", order: 6,  type: 'webinar', duration: '0:11:56', fileName: 'DEFI.Frontrun_and_sandwich_attacks_when_working_with_DEX.m4v', title: 'Что такое фронтран- и сендвич-атаки при работе с DEX'},
      {hls: true, url: "f7f53d29aab596701755fe3049b23b9089b76a56a2280e32dda025bd813e7ed5", order: 7,  type: 'webinar', duration: '0:16:47', fileName: 'DEFI.How_not_to_buy_a_scam.m4v', title: 'Как не купить СКАМ'},
      {hls: true, url: "4291e60283e51e9e4d24d763f0ee6a2ded8af82c41b8c23e48156274ada84574", order: 8,  type: 'webinar', duration: '0:11:35', fileName: 'DEFI.DropTab_platform.m4v', title: 'Обзор аналитической платформы DropsTab'},
      {hls: true, url: "2e4d9e25dfa7cc71479ae62f0cbf302458eb51e195b4f2c86a37f155dcb10297", order: 9,  type: 'webinar', duration: '0:14:27', fileName: 'DEFI.Decentralized_exchange_review__GMX.m4v', title: 'Обзор децентрализованной биржи - GMX'},
      {hls: true, url: "978eae909b33ad38fd0e07c43ba44ede038e063a817140b3462af7fb52dbba68", order: 10, type: 'webinar', duration: '0:09:59', fileName: 'DEFI.Execution_of_the_quiz_ZkSync_Era.m4v', title: 'Прохождение квиза ZkSync Era'},
      {hls: true, url: "02eb264202bf45fab0ab581ff42480c084aa154c2eeabe20217e5f9f763d4ff0", order: 11, type: 'webinar', duration: '0:20:13', fileName: 'DEFI.Running_activities_on_Base_and_Syndicate.m4v', title: 'Выполнение активностей на Base и Syndicate',
        description: (
          <>
            <div>Инструкция для Base тут: <Link className="underline" to={{ pathname: 'https://responsible-handball-463.notion.site/Base-a6afd2b7fa9b498da29530d6c4a430e2'}} target='_blank'>Ссылка на гайд №1</Link></div>
            <div>Инструкция для Syndicate тут: <Link className="underline" to={{ pathname: 'https://responsible-handball-463.notion.site/Syndicate-49efe02756ae48859ae8b0f28b0a7f60'}} target='_blank'>Ссылка на гайд №2</Link></div>
            <div>Пример таблицы для ведения тестнетов: <Link className="underline" to={{ pathname: 'https://docs.google.com/spreadsheets/d/1xu1HThwuZFN2xhJgSqGWq-9wY8-PWBKLt6fe-rwZRAk/edit#gid=0'}} target='_blank'>Ссылка на таблицу</Link></div>
            <div>Новый пример таблицы для ведения тестнетов: <Link className="underline" to={{ pathname: 'https://docs.google.com/spreadsheets/d/1ZMpriNmZhpSSSmif8c7nYo7bA7IWgO9svJObqbS3kKA/edit?usp=sharing'}} target='_blank'>Ссылка на новую таблицу</Link></div>
          </>
        ),
      },
      {hls: true, url: "2776a804d15990b2ba5fc8f4143cd934141be1d71a761a083e52473aafbee0c5", order: 12, type: 'webinar', duration: '0:10:09', fileName: 'DEFI.Running_activities_on_testnet_from_Venum_Foundation.m4v', title: 'Проходим тестнет от Venum Foundation',
        description: (
          <>
            <div>Инструкция для Venum testnet тут: <Link className="underline" to={{ pathname: 'https://responsible-handball-463.notion.site/Venum-testnet-429969ed586b4c77b659924c12ac293f'}} target='_blank'>Ссылка на гайд</Link></div>
          </>
        ),
      },
      {hls: true, url: "4eab7af936e4587998893d633652d33edb735d90b6193259944ff4d648e3d49c", order: 13, type: 'webinar', duration: '0:13:35', fileName: 'DEFI.BRC-20_Tokens_and_their_perspectives__UniSat_wallet.m4v', title: 'Токены BRC-20 и их перспективы, кошелек UniSat wallet'},
      {hls: true, url: "f4399815b92b3b5d9bbff57a670114ba032e9a1f5bbfb31fd7e6416e12e7f037", order: 14, type: 'webinar', duration: '0:13:57', fileName: 'DEFI.Uniswap_exchange.m4v', title: 'Децентрализованная биржа Uniswap'},
      {hls: true, url: "8d8ed74b1ba3c3db2e7ca3bd3cafc41f539d1aff648bb41eb14c339e2ccb1732", order: 15, type: 'webinar', duration: '0:20:41', fileName: 'DEFI.Earning_strategies.m4v', title: 'Cтратегии заработка'},
      {hls: true, url: "ae8aec6af805f793a60214a88c3453472238821287ccff484d5af51a52efef1b", order: 16, type: 'webinar', duration: '0:13:08', fileName: 'DEFI.Platform_Aave.m4v', title: 'Платформа Aave'},
    ],
    description: (
      <div>
        <div>Децентрализованные финансы — это понятие стремительно ворвалось в повседневную жизнь и стало синонимом технологического развития, интернета web 3.0, новой финансовой реальности. Разобраться в этих вопросах поможет наш факультет Defi.</div>
        <div>Вы получите:</div>
        <ul>
          <li>Понимание глобальных трендов цифровой экономики, расширение общего кругозора;</li>
          <li>Представление, что такое блокчейн как работает Ethereum, цифровые активы, децентрализованные биржи и смарт-контракты;</li>
          <li>Знание экосистем DeFi: приложение DeFi I (кредитование / заимствование, flash-кредиты, деривативы) и приложения DeFi II — III (automated market making, yieldfarming, синтетические активы, оракулы, страхование)</li>
          <li>Знакомство с самым популярными кошельками и dapp’s.</li>
          <li>Первый заработок с DeFi: в процессе обучения вы научитесь создавать LP токены и зарабатывать на стэйкинге и фарминге.</li>
          <li>Создадите свою инвестиционную стратегию работы с DeFi.</li>
          <li>И многое другое.. Так как рынок быстро развивается у вас будет постоянная база актуальных знаний.</li>
        </ul>
        <div>&nbsp;</div>
        <button className="standart-btn" onClick={() => openInNewTab(TELEGRAM_FOR_DEFI)}>
          <div>
            Перейти на Телеграмм-канал факультета "DeFi"
          </div>
        </button>
      </div>
    )
  },
  {programs_category: 2, order: 6, fileImageNumber: 7, code: "crypto",  title: "Криптотрейдинг",    exclude_roles: [ROLE_BEGINNER, ROLE_STUDENT_BASE, ROLE_STUDENT_BASE_PLUS], linkPath: ROUTE_EDUCATION_CRYPTO_TRADING,
    files: [
      {hls: true, url: "9be043bc1e1010a2ae81e7d9aaffea2772da2fbb1fbb6b6d65aa71ee31afaf77", order: 1, type: 'video', duration: '0:06:28', fileName: 'Crypto_Trading.lesson_1.Intro.m4v', title: 'Урок 1. Введение.'},
      {hls: true, url: "9f594e407359241f1ec14d9d40caea0c0204d6bf1464754899b7126204ae08c3", order: 2, type: 'video', duration: '0:12:49', fileName: 'Crypto_Trading.lesson_2.Technical_analytics.m4v', title: 'Урок 2. Технический анализ.'},
      {hls: true, url: "b43cdbeebfa8d6152d69828869160803eddfe3df0b1c57f84792ebe4a8cc0093", order: 3, type: 'video', duration: '0:08:03', fileName: 'Crypto_Trading.lesson_3.Terms_of_trading.m4v', title: 'Урок 3. Терминология трейдинга.'},
      {hls: true, url: "9458d6ca7f1852ceb959fdb0507b295b04ec368c50c1a8e52977e6f24d60c82a", order: 4, type: 'video', duration: '0:17:27', fileName: 'Crypto_Trading.lesson_4.Tools_filters_indicators.m4v', title: 'Урок 4. Дополнительные инструменты технического анализа, фильтры и индикаторы.'},
      {hls: true, url: "c94e782368d31f50d97458ce731b623b23f1d5f4acc05a225f01525149bd8615", order: 5, type: 'video', duration: '0:04:18', fileName: 'Crypto_Trading.lesson_5.Risks_crypto-currency_management_portfolio.m4v', title: 'Урок 5. Риск и мани-менеджмент, отбор инструментов и формирование крипто-портфеля.'},

      {hls: true, url: "281ab05f70bea6261378c7b9e0cb749de545989d6cc43709df902a98c143da66", order: 1, type: 'webinar', duration: '0:09:16', fileName: 'Crypto_Trading.TradingView.m4v', title: 'Аналитическая платформа TradingView.'},
      {hls: true, url: "17ef35c3090ad353d289f324245012ce8a6a012cff94ec1793bcc207cddfa83e", order: 2, type: 'webinar', duration: '0:16:31', fileName: 'Crypto_Trading.Choice_of_exchanges_and_brief_review_of_each_one.m4v', title: 'Выбор бирж и краткий обзор по каждой из них. Функционал бирж. Виды ордеров, и как ими пользоваться при торговле.'},
      {hls: true, url: "87729c4ba3023948db05a76ac86ab74b01c71a605d09275f12a849220fd129ec", order: 3, type: 'webinar', duration: '0:08:54', fileName: 'Crypto_Trading.Index_of_fear.m4v', title: 'Индекс страха.'},
      {hls: true, url: "6d3e2c9b26dc0fb9f61a5564835c926d2aa252f2de3026ee94b7dcb2605ebf49", order: 4, type: 'webinar', duration: '0:03:07', fileName: 'Crypto_Trading.Classification_of_assets.m4v', title: 'Классификация активов на Крипто-рынке. Правильный отбор активов.'},
      {hls: true, url: "e0f2900496ef5c6f1b46588e11004ef98dd646c270d512b7f06bdaedf3359d77", order: 5, type: 'webinar', duration: '0:09:34', fileName: 'Crypto_Trading.Trend_trading_using_the_Dow_theory.m4v', title: 'Торговля по тренду применяя теорию ДОУ.'},
      {hls: true, url: "5c434b8a321c698d3b9e61c929ed3a2acd1b50c947801a72acd61721f0e003ab", order: 6, type: 'webinar', duration: '0:11:20', fileName: 'Crypto_Trading.Pump_and_dump.m4v', title: 'Индикаторы памп и дамп рынка.'},
      {hls: true, url: "cb9d29b2bcc69e641050931a17eb6a8422f08bea1d402eaf4440a2b540c886ff", order: 7, type: 'webinar', duration: '0:10:06', fileName: 'Crypto_Trading.How_to_make_your_trading_strategy.m4v', title: 'Как разработать свою торговую стратегию.'},
      {hls: true, url: "151e2c0d6aa4a47d422262f197e794e5ed390306452330ef8b184c9505ee0776", order: 8, type: 'webinar', duration: '0:07:44', fileName: 'Crypto_Trading.Coin_analysis_using_the_OBV_indicator_(volume).mp4', title: 'Анализ монет при помощи индикатора OBV (объем).'},
      {hls: true, url: "6636a7c861f36cd04488192dd881fc179f597468c87d6219a408870a93242a5e", order: 9, type: 'webinar', duration: '0:12:33', fileName: 'Crypto_Trading.Swing_Trade_Strategy_with_Fibonacci_Grid.mp4', title: 'Стратегия свинг трейда при помощи сетки Фибоначчи! Уровни для входа в позицию и фиксации прибыли.'},
      {hls: true, url: "09541543164d69a9d8a479f37d4e7d64629ee57e5d71683b7a314c5edebea3a0", order: 10, type: 'webinar', duration: '0:18:04', fileName: 'Crypto_Trading.Trading_strategy_according_to_Thomas_DeMark.m4v', title: 'Стратегия Торговли по Томасу Демарку.'},
      {hls: true, url: "658a95faa4dd3eb03559e8e67fb56f7a8bbe8eb1c74499f7cd804a3b680b1519", order: 11, type: 'webinar', duration: '0:26:15', fileName: 'Crypto_Trading.Price_action_strategy.m4v', title: 'Стратегия прайс-экшен.'},
      {hls: true, url: "62c9a71838f4fadb201d0713676521dee103215d49cae40d03a7d20e4a606c38", order: 12, type: 'webinar', duration: '0:45:05', fileName: 'Crypto_Trading.Market_algorithm_and_trend_trading.m4v', title: 'Рыночный алгоритм и торговля по тренду.'},
    ],
    description: (
      <div>
        <div>Уверен что многие слышали что такое Трейдинг или спекуляция на крипто-рынке, но мало кто знает что это целая не легкая профессия и искусство! Факультет крипто-трейдинга выступает проводником в мир спекуляций.</div>
        <div>На нашем факультет вы узнаете:</div>
        <ul>
          <li>Что такое рынок</li>
          <li>Что такое трейдинг или трейдер</li>
          <li>Познакомитесь и научитесь применять основные инструменты Технического Анализа</li>
          <li>Научитесь читать график и самостоятельно анализировать активы</li>
          <li>Научитесь находить грамотные точки входа в рынок, фиксировать прибыль согласно своей торговой стратегии</li>
          <li>Выстроите свою личную торговую стратегию</li>
          <li>Придет понимание как не быть крипто-хомяком</li>
          <li>Риск и манименеджмент, отбор инструментов и формирование криптопортфеля.</li>
          <li>Наш факультет — это 99% практики и 1% важной и применимой теории.</li>
        </ul>
        <div>&nbsp;</div>
        <button className="standart-btn" onClick={() => openInNewTab(TELEGRAM_FOR_CRYPTO)}>
          <div>
            Перейти на Телеграмм-канал факультета "Криптотрейдинг"
          </div>
        </button>
      </div>
    )
  },
  {programs_category: 2, order: 7, fileImageNumber: 5, code: "nft", title: "NFT", exclude_roles: [ROLE_BEGINNER, ROLE_STUDENT_BASE, ROLE_STUDENT_BASE_PLUS], linkPath: ROUTE_EDUCATION_NFT,
    files: [
      {hls: true, url: '9b400fd37361f0150818ce384092ad8733316401f0c6f5acfda4c5208642a347', order: 1, type: 'video', duration: '0:11:39', fileName: 'NFT.lesson_1.History_of_cryptocurrencies.mp4', title: 'Урок 1. История криптовалют.'},
      {hls: true, url: 'a760c9d4e9c66cc85372f44d831f3827deffec7d9dcc2ad8f08029a30362bd04', order: 2, type: 'video', duration: '0:09:40', fileName: 'NFT.lesson_2.What_is_NFT.mp4', title: 'Урок 2. Что такое NFT.'},
      {hls: true, url: '340df4cb69eeba308dc8fedf34cd5d77ac222c7ae60d6b4cfd7f71c9e2c912db', order: 3, type: 'video', duration: '0:12:55', fileName: 'NFT.lesson_3.Using_services_and_benefiting_from_NFTs.mp4', title: 'Урок 3. Использование сервисов и выгода в NFT.'},
      {hls: true, url: '515ce2d70ec57f78ec06e9ef02026cd69b7eb77140e8e02d72d1e8eb9f56ca2b', order: 4, type: 'video', duration: '0:09:18', fileName: 'NFT.lesson_4.NFT_Security_Basics.mp4', title: 'Урок 4. Основы безопасности в NFT.'},

      // {hls: true, url: '57f9c66f546edf3856f17e0d86e722a1b53337cecb2520496ef98ff5679f07e7', order: 1, type: 'webinar', duration: '0:06:06', fileName: 'Game_Fi.1.m4v', title: 'Вебинар 1.'},
    ],
    description: (
      <div>
        <div>NFT -  невзаимозаменяемый токен, уникальная запись в блокчейне децентрализованной базе данных.</div>
        <div>Главная особенность NFT - невозможность замены, подмены и изменения информации. Это делает его идеальным инструментом для подтверждения права владения цифровым активом.</div>
        <div>Не думайте, что это просто "картинки"</div>
        <div>
          <ul>На своем факультете я научу вас:
            <li>Разбираться в направлении NFT</li>
            <li>Расскажу как NFT изменит мир</li>
            <li>Искать и зарабатывать на продаже коллекций</li>
            <li>Составлять средне-долгосрочный портфель</li>
            <li>Отправлять в стейкинг NFT</li>
            <li>Расскажу о перспективе NFT в бизнесе</li>
          </ul>
        </div>
        <div>Это только начало, будущее среди нас!</div>
        <div>
         <button className="standart-btn" onClick={() => openInNewTab(TELEGRAM_FOR_NFT)}>
           <div>
             Перейти на Телеграмм-канал факультета "NFT"
           </div>
         </button>
        </div>
      </div>
    ),
  },
  /* {
    programs_category: 2, order: 7, fileImageNumber: 2, code: "game_fi", title: "GameFi, Metaverse", exclude_roles: [ROLE_BEGINNER, ROLE_STUDENT_BASE, ROLE_STUDENT_BASE_PLUS], linkPath: ROUTE_EDUCATION_GAMEFI,
    files: [
      // {order: 1, type: 'video', duration: '0:06:06', fileName: 'Game_Fi.lesson_1.m4v', title: 'Урок 1. '},
      // {order: 2, type: 'video', duration: '0:08:09', fileName: 'Game_Fi.lesson_2.m4v', title: 'Урок 2. '},
      // {order: 3, type: 'video', duration: '0:04:22', fileName: 'Game_Fi.lesson_3.Guilds.m4v', title: 'Урок 3. Гильдии.'},
      {hls: true, url: '51d4e90ff530d37b6807163932123ab56f127d8bab8439999b78d54f77c94a72', order: 1, type: 'video', duration: '0:06:35', fileName: 'Game_Fi.lesson_1.Intro.mp4', title: 'Урок 1. Введение.'},
      {hls: true, url: '9e3027f2c53c40119f60b0cfe434585e817410ce1b34168d89bb9d50b1e2ed66', order: 2, type: 'video', duration: '0:12:56', fileName: 'Game_Fi.lesson_2.Blockchain_NFT_in-game_currency.mov', title: 'Урок 2. Блокчейн, NFT, внутри-игровая валюта.'},
      {hls: true, url: 'c1aab3003d750789b43b098ddc1a5607c68a15861ba06779961aa344bd81c511', order: 3, type: 'video', duration: '0:09:41', fileName: 'Game_Fi.lesson_3.Benefits_and_differences.mov', title: 'Урок 3. Преимущества и отличия.'},
      {hls: true, url: 'cf700dc7014899d1cc131e5d1722d14abcb146e683e474c7abedc27d792facd4', order: 4, type: 'video', duration: '0:09:52', fileName: 'Game_Fi.lesson_4.History_and_problems.mov', title: 'Урок 4. История и проблемы.'},
      {hls: true, url: 'af00b45781b9d7af8478d09576b2b14c5d1c026c7b9a5e81e79d2f8115a0b5ae', order: 5, type: 'video', duration: '0:07:28', fileName: 'Game_Fi.lesson_5.What_will_make_the_gamefie_stronger.mov', title: 'Урок 5. Что сделает Геймфай крепче.'},

      {hls: true, url: '57f9c66f546edf3856f17e0d86e722a1b53337cecb2520496ef98ff5679f07e7', order: 1, type: 'webinar', duration: '0:06:06', fileName: 'Game_Fi.1.m4v', title: 'Вебинар 1.'},
      {hls: true, url: '94a438753e68cf040eed5dd73333293c5e87863fcacd44fc8f969d21fea4f6ea', order: 2, type: 'webinar', duration: '0:04:22', fileName: 'Game_Fi.Guilds.m4v', title: 'Гильдии.'},
      {hls: true, url: 'e56b247a1e0e332383c31efd8540e31ac511d78fd048456bf39718b5c57c2582', order: 3, type: 'webinar', duration: '0:21:41', fileName: 'Game_Fi.Discord.m4v', title: 'Инструмент Discord.'},
      {hls: true, url: '7f5ac1c6ce52fe14f9eef6a29557ee093b2c6eab38b38def0fd6bc6044d3d8d5', order: 4, type: 'webinar', duration: '0:16:13', fileName: 'Game_Fi.Marketplaces.mov', title: 'Маркетплейсы.'},
      {hls: true, url: '4f1d4a12c505fb467c7fcb54befc137525c10b0c08c62fb8d59d6614adc9fc00', order: 5, type: 'webinar', duration: '0:24:03', fileName: 'Game_Fi.Quests.m4v', title: 'Квесты.'},
      {hls: true, url: '9ee1abc46c4ff1d79d1b971ed666228073743252ef86100dfbb87659b483a4e8', order: 6, type: 'webinar', duration: '0:31:51', fileName: 'Game_Fi.Scams_ragpools_and_how_to_avoid_them.m4v', title: 'Cкамы, рагпулы и как их избежать.'},
      {hls: true, url: '7fb3918f283dde5e3c315a60ab7cfe827d58461011a9bcb0d6a0bf16920a132e', order: 7, type: 'webinar', duration: '0:36:19', fileName: 'Game_Fi.May_holidays_in_Gala_Games.m4v', title: 'Майские праздники в Гала геймз.'},
      {hls: true, url: 'c3c6d3e9d69683649470d46bb42bb8b2d8a4aab79c682943e2fb4b85fdd5dd19', order: 8, type: 'webinar', duration: '0:24:49', fileName: 'Game_Fi.Superior_Gala_Games.m4v', title: 'Прохождение игры Superior от Gala Games'},
      {hls: true, url: "930bd04fdd69945f3efe15577495103d5d5b7b2800f8c9894cb8734a00b3b005", order: 9, type: 'webinar', duration: '0:22:53', fileName: 'Game_Fi.Lesson_on_the_Delysium_project.m4v', title: 'Урок по проекту Delysium'},    ],
    main_video: {hls: true, url: '5017c23e6c08416ad09632ee60ca2c4c1c0a7a700d780ed04181a69c846aa71f', order: 1, type: 'video', duration: '0:00:25', fileName: 'Game_Fi.main.Dealers_guild_promo.mp4', title: undefined, poster: 'Game_Fi.Main.Poster.png',
      // description: (
      //   <div>
      //     <h2>Задача депортамента</h2>
      //     <p>Смарт-контракты для ICO в свое время произвели настоящий фурор. </p>
      //   </div>
      // ),
    },
    description: (
      <div>
        <div>Факультет GameFi. На всё о том,  что в себе несёт новая и захватывающая индустрия игр нового поколения, построенных на технологиях блокчейна и NFT.</div>
        <div>На нашем факультет мы вместе с вами:</div>
        <ul>
          <li>изучим историю и теорию GameFi;</li>
          <li>разберёмся во всех все возможных инструменты для приумножения своего капитала, которые есть в этой индустрии,;</li>
          <li>будем совместно  инвестировать в перспективные активы(токены и NFT) GameFi проектов на ранних стадиях;</li>
          <li>будем регулярно мониторить рынок на предмет интересных для нас проектов;</li>
          <li>играть в p2e и зарабатывать в них;</li>
          <li>обмениваться опытом и вместе становиться сильнее 🙌</li>
        </ul>
        <div>Наш факультет будет интересен и  инвесторам, и криптоэнтузиастам, и безусловно геймерам.</div>
        <div>До встречи на факультете 👍</div>
        <div>&nbsp;</div>
        <button className="standart-btn" onClick={() => openInNewTab(TELEGRAM_FOR_GAMEFI)}>
          <div>
            Перейти на Телеграмм-канал факультета "GameFi"
          </div>
        </button>
      </div>
    ),
  },*/
];

export const consultant = {programs_category: null, order: 1, fileImageNumber: null, code: "consultant", title: "Папка Амбассадора", exclude_roles: [ROLE_BEGINNER, ROLE_STUDENT_BASE, ROLE_STUDENT_ACADEMY, ROLE_STUDENT_BASE_PLUS, ROLE_STUDENT_ACADEMY_PLUS],
  // linkPath: ROUTE_CONSULTANT,
  linkPath: ROUTE_UNIVERSITY_CONSULTANT,
  parent: {
    name: 'Корпоративный университет',
    link: '/university',
  },
  files: [
    {url:'https://www.youtube.com/watch?v=U-uFZM_Nkoo', order: 9,  type: 'video', duration: '1:17:53', title: 'Линн Эдди Айронс - легендарный бизнес тренер МИРОВОГО УРОВНЯ, специально для SoulMate'},
    // {url:'https://www.youtube.com/watch?v=u9Y8_0GTxhA', order: 11, type: 'video', duration: '0:05:32', title: 'Разговор на чистоту с основателем SoulMate - Максим Бумарсков'},
    // {url:'https://www.youtube.com/watch?v=cAhXwUsKujg', order: 12, type: 'video', duration: '0:05:30', title: 'Разговор на чистоту с основателем SoulMate - Дмитрий Петров'},
    // {url:'https://www.youtube.com/watch?v=-_zyHbbR3c0', order: 13, type: 'video', duration: '0:06:01', title: 'Разговор на чистоту с основателем SoulMate - Виталий Пятенко'},
    // {url:'https://www.youtube.com/watch?v=Kxs_Ab1aYvc', order: 14, type: 'video', duration: '0:06:09', title: 'Александр Сальников. Почему я как эксперт рынка криптовалют, выбрал SoulMate'},
    // {url:'https://www.youtube.com/watch?v=sFUnEo10I3g', order: 15, type: 'video', duration: '0:06:50', title: 'Александр Гринев. Почему я как эксперт рынка криптовалют, выбрал SoulMate'},
    // {url:'https://www.youtube.com/watch?v=uX9mb2bfvqc', order: 16, type: 'video', duration: '0:03:21', title: 'Антон Костюков. Почему я как эксперт рынка криптовалют, выбрал SoulMate'},
    // {url:'https://www.youtube.com/watch?v=7km9cvUDDAA', order: 18, type: 'video', duration: '1:16:32', title: 'Брифинг с основателями SoulMate'},

    {order: 6,  type: 'book', fileName: 'Consultant.Marketing_plan_and_generation_earning_strategies.pdf',  ext: 'pdf', size: 10453898,  title: 'Маркетинг план и стратегии создания дохода.pdf'},
    {order: 8,  type: 'book', fileName: 'Consultant.Company_goals_and_missions.pdf',                        ext: 'pdf', size: 4885275,   title: 'Цели и миссии компании.pdf'},
    {order: 9,  type: 'book', fileName: 'Consultant.Ethical_Standards_and_Rules_SoulMate.pdf',              ext: 'pdf', size: 21109387,  title: 'Этические стандарты и правила SoulMate.pdf'},
    // {order: 10, type: 'book', fileName: 'Consultant.Product_list.pdf',                                      ext: 'pdf', size: 22988158,  title: 'Продукты компании SoulMate.pdf'},
    // {order: 11, type: 'book', fileName: 'Consultant.Bullran.pdf',                                           ext: 'pdf', size: 9055352,   title: 'Презентация Bullran Index.pdf'},
    {order: 12, type: 'book', fileName: 'Consultant.Program_and_result_for_BC.pdf',                         ext: 'pdf', size: 2567277,   title: 'Программа и результаты Базового курса для начинающих инвесторов крипто-академии SoulTeam.pdf'},
    {order: 13, type: 'book', fileName: 'Consultant.Program_and_result_for_CA.pdf',                         ext: 'pdf', size: 15523653,  title: 'Программа и результаты факультетов крипто-академии SoulTeam.pdf'},
    // {order: 14, type: 'book', fileName: 'Consultant.Guide_for_products_of_CA.pdf',                          ext: 'pdf', size: 21512877,  title: 'Гайд по продуктам Крипто-Академии SoulTeam для амбассадоров.pdf'},

    /*
    Стрые настройки (до 2023-11-12)
    {url:'https://www.youtube.com/watch?v=KvSGLPB2ygI', order: 1,  type: 'video', duration: '0:54:35', title: 'Максим Бумарсков - co founder SoulMate. Тема: Презентация компании SoulMatе'},
    {url:'https://www.youtube.com/watch?v=zzGaxymo5fw', order: 2,  type: 'video', duration: '0:50:46', title: 'Дмитрий Петров - co founder Soulmate. Тема: Презентация продуктов SoulMate'},
    {url:'https://www.youtube.com/watch?v=E5ic4iILMFE', order: 3,  type: 'video', duration: '0:31:33', title: 'Презентация «факультета Defi» - Александр Сальников'},
    {url:'https://www.youtube.com/watch?v=ycbNahNCfwQ', order: 4,  type: 'video', duration: '0:32:57', title: 'Презентация «факультета IDO and ICO» - Хачатур Авдалян'},
    {url:'https://www.youtube.com/watch?v=v1bQ1PFK0Tg', order: 5,  type: 'video', duration: '0:37:24', title: 'Презентация «факультета GameFi and metaverse» - Александр Гринев'},
    {url:'https://www.youtube.com/watch?v=tnNbjgo81hQ', order: 6,  type: 'video', duration: '0:22:12', title: 'Презентация факультета «Crypto trading» - Павел Бутенко'},
    {url:'https://www.youtube.com/watch?v=MsdS9Tikt0Y', order: 7,  type: 'video', duration: '1:28:16', title: 'Виталий Пятенко - co founder SoulMate. Тема: Карьера в SoulMate'},
    {url:'https://www.youtube.com/watch?v=N6P2YYDyLT4', order: 8,  type: 'video', duration: '1:12:20', title: 'Дмитрий Петров - co founder SoulMate. Тема: Развитие и построение бизнеса SoulMate'},
    {url:'https://www.youtube.com/watch?v=U-uFZM_Nkoo', order: 9,  type: 'video', duration: '1:17:53', title: 'Линн Эдди Айронс - легендарный бизнес тренер МИРОВОГО УРОВНЯ, специально для SoulMate'},
    {url:'https://www.youtube.com/watch?v=0knyWYGJyQ0', order: 10, type: 'video', duration: '1:32:18', title: 'Максим Бумарсков и Виталий Пятенко - co founder SoulMate. Тема: Перспективы бизнеса SoulMate'},
    {url:'https://www.youtube.com/watch?v=u9Y8_0GTxhA', order: 11, type: 'video', duration: '0:05:32', title: 'Разговор на чистоту с основателем SoulMate - Максим Бумарсков'},
    {url:'https://www.youtube.com/watch?v=cAhXwUsKujg', order: 12, type: 'video', duration: '0:05:30', title: 'Разговор на чистоту с основателем SoulMate - Дмитрий Петров'},
    {url:'https://www.youtube.com/watch?v=-_zyHbbR3c0', order: 13, type: 'video', duration: '0:06:01', title: 'Разговор на чистоту с основателем SoulMate - Виталий Пятенко'},
    {url:'https://www.youtube.com/watch?v=Kxs_Ab1aYvc', order: 14, type: 'video', duration: '0:06:09', title: 'Александр Сальников. Почему я как эксперт рынка криптовалют, выбрал SoulMate'},
    {url:'https://www.youtube.com/watch?v=sFUnEo10I3g', order: 15, type: 'video', duration: '0:06:50', title: 'Александр Гринев. Почему я как эксперт рынка криптовалют, выбрал SoulMate'},
    {url:'https://www.youtube.com/watch?v=uX9mb2bfvqc', order: 16, type: 'video', duration: '0:03:21', title: 'Антон Костюков. Почему я как эксперт рынка криптовалют, выбрал SoulMate'},
    // {url:'https://www.youtube.com/watch?v=tU_-5itgrDQ', order: 17, type: 'video', duration: '0:06:14', title: 'Денис Плющенко. Почему SoulMate компания будущего'},
    {url:'https://www.youtube.com/watch?v=7km9cvUDDAA', order: 18, type: 'video', duration: '1:16:32', title: 'Брифинг с основателями SoulMate'},

    {order: 1,  type: 'book', fileName: 'Consultant.Answers_to_objections.pdf',                             ext: 'pdf', size: 6665367,   title: 'Ответы на возражения.pdf'},
    {order: 2,  type: 'book', fileName: 'Consultant.Sales_script.pdf',                                      ext: 'pdf', size: 8653418,   title: 'Скрипты продаж.pdf'},
    {order: 3,  type: 'book', fileName: 'Consultant.Ticket_purchase_script.pdf',                            ext: 'pdf', size: 1868338,   title: 'Скрипты контроля покупки билетов.pdf'},
    {order: 4,  type: 'book', fileName: 'Consultant.Master_class_script.pdf',                               ext: 'pdf', size: 2157038,   title: 'Скрипты приглашения на мастер-класс.pdf'},
    {order: 5,  type: 'book', fileName: 'Consultant.Master_class_slides.pdf',                               ext: 'pdf', size: 26343132,  title: 'Слайды для проведения мастер-класса.pdf'},
    {order: 6,  type: 'book', fileName: 'Consultant.Marketing_plan_and_generation_earning_strategies.pdf',  ext: 'pdf', size: 10453898,  title: 'Маркетинг план и стратегии создания дохода.pdf'},
    {order: 7,  type: 'book', fileName: 'Consultant.Notebook_72_hours.pdf',                                 ext: 'pdf', size: 238405593, title: 'Тетрадь 72 часа.pdf'},
    {order: 8,  type: 'book', fileName: 'Consultant.Company_goals_and_missions.pdf',                        ext: 'pdf', size: 4885275,   title: 'Цели и миссии компании.pdf'},
    {order: 9,  type: 'book', fileName: 'Consultant.Ethical_Standards_and_Rules_SoulMate.pdf',              ext: 'pdf', size: 21109387,  title: 'Этические стандарты и правила SoulMate.pdf'},
    {order: 10, type: 'book', fileName: 'Consultant.Product_list.pdf',                                      ext: 'pdf', size: 22988158,  title: 'Продукты компании SoulMate.pdf'},
    {order: 11, type: 'book', fileName: 'Consultant.Bullran.pdf',                                           ext: 'pdf', size: 9055352,   title: 'Презентация Bullran Index.pdf'},
    */
  ],
};

export const newFilesForPrograms = [
  // {type: 'video', fileName: 'Game_Fi.lesson_1.Intro.mp4',                               title: 'Урок 1. Введение.'},
  // {type: 'video', fileName: 'Game_Fi.lesson_2.Blockchain_NFT_in-game_currency.mov',     title: 'Урок 2. Блокчейн, NFT, внутри-игровая валюта.'},
  // {type: 'video', fileName: 'Game_Fi.lesson_3.Benefits_and_differences.mov',            title: 'Урок 3. Преимущества и отличия.'},
  // {type: 'video', fileName: 'Game_Fi.lesson_4.History_and_problems.mov',                title: 'Урок 4. История и проблемы.'},
  // {type: 'video', fileName: 'Game_Fi.lesson_5.What_will_make_the_gamefie_stronger.mov', title: 'Урок 5. Что сделает Геймфай крепче.'},

  // {order: 1, type: 'book', fileName: 'ICO_IDO.CoinList.Registration_and_verification_(instructions).docx', ext: 'docx', size: '1853915', title: 'Инструкция по регистрации и верификации на сайте CoinStar'},

  // {type: 'book', fileName: 'Consultant.Answers_to_objections.pdf', size: 6665367, title: 'Ответы на возражения.pdf'},
  // {type: 'book', fileName: 'Consultant.Sales_script.pdf', size: 8653418, title: 'Скрипты продаж.pdf'},
  // {type: 'book', fileName: 'Consultant.Ticket_purchase_script.pdf', size: 1868338, title: 'Скрипты_контроля_покупки_билетов.pdf'},
  // {type: 'book', fileName: 'Consultant.Master_class_script.pdf', size: 2157038, title: 'Скрипты_приглашения_на_мастер_класс.pdf'},
  // {type: 'book', fileName: 'Consultant.Earning_strategies.pdf', size: 3206315, title: 'Стратегии для заработка.pdf'},
  // {type: 'book', fileName: 'Consultant.Notebook_72_hours.pdf', size: 238405593, title: 'Тертрать 72 часа.pdf'},
  // {type: 'book', fileName: 'Consultant.Company_goals_and_missions.pdf', size: 4885275, title: 'Цели и миссии компании.pdf'},
  // {type: 'book', fileName: 'Consultant.Ethical_Standards_and_Rules_SoulMate.pdf', size: 21109387, title: 'Этические_стандарты_и_правила_SoulMate.pdf'},

  // {type: 'book', fileName: 'Consultant.Marketing_plan.pdf', size: 6246682, title: 'Маркетинг-план.pdf'},
  // {type: 'book', fileName: 'Consultant.Product_list.pdf', size: 15037776, title: 'Продукты компании SoulMate.pdf'},

  // {type: 'webinar', duration: '0:12:18', fileName: 'Crypto_Trading.TradingView.m4v', title: 'TradingView'},
  // {type: 'webinar', duration: '0:11:05', fileName: 'DEFI.TrustWallet.m4v', title: 'TrustWallet'},
  // {type: 'webinar', duration: '0:06:06', fileName: 'Game_Fi.1.m4v', title: ''},
  // {type: 'webinar', duration: '0:19:49', fileName: 'ICO_IDO.Maiar.m4v', title: ''},
]
