import getRouteText from '@src/helpers/getRouteText';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Form, Input, message, Table } from 'antd';
import dayjs from 'dayjs';

import { LeftSquareOutlined, RightSquareOutlined, SaveOutlined } from '@ant-design/icons';
import CatalogHeader from '@common/catalogHeader';
import MobileHeader from '@common/mobileHeader';
import api from '@services/api';
import { PAGE_CHANGER_STEPS, ROUTE_REPORT } from '@src/constants';

import "./styles.less";

require('dayjs/locale/ru')

class ReportVolumeUserListPage extends Component {
  formRef = React.createRef();

  state = {
    userListVolumes: [],
    userListText: '',
    loading: false,
    period: 0,
    periodText: undefined,
  }

  componentDidMount() {
    this.getVolumeUserList();
  }

  getVolumeUserList = async () => {
    const { period } = this.state;
    this.setState({ loading: true });

    const { status, data } = await api.getReportVolumeUserList({ period });

    if (status === 200) {
      if (data.status === 0) {
        if (data?.volume?.length) {
          this.setState({ userListVolumes: data.volume });
        } else {
          this.setState({ userListVolumes: [] });
        }
        if (data?.date_begin?.length) {
          this.setState({ periodText: dayjs(data.date_begin).locale('ru').format('MMM YYYY') });
        } else {
          this.setState({ periodText: undefined });
        }
      } else {
        message.error(data.message);
      }
      this.formRef?.current?.setFieldsValue({ user_list: data?.user_list ?? '' });
    } else {
      message.error("Ошибка получения данных ...");
    }

    this.setState({ loading: false });
  }

  changePage = (newPage, newPageSize) => {
    const { pageSize } = this.state;

    this.setState({
      pageSize: newPageSize,
      page: Number(pageSize) !== newPageSize ? 1 : newPage,
    }, async () => await this.getVolumeUserList())
  }

  onChangePeriod = (e, delta) => {
    e.preventDefault();
    this.setState((state) => ({
      period: state.period + delta,
    }), async () => await this.getVolumeUserList());
  }

  onChangeUserList = (e) => {
    e.preventDefault();
    this.setState({ userListText: e?.target?.value ?? '' })
  }

  onSaveUserList = async (e) => {
    const { userListText } =  this.state;

    e.preventDefault();

    const { status, data } = await api.updateReportVolumeUserList(userListText);

    if (status === 200 && data.status === 0) {
      message.success(data.message);
      this.formRef?.current?.setFieldsValue({ user_list: data?.user_list ?? '' });
      await this.getVolumeUserList();
    } else {
      message.error(data?.message ?? 'Ошибка сохранения списка пользователей ...');
    }
  }

  render() {
    const { t } = this.props;
    const { userListVolumes, loading, period, periodText } = this.state;

    const date = periodText || dayjs().locale('ru').subtract((-1) * period, 'month').format('MMM YYYY');

    const columns = [
      {
        title: 'Пользователь',
        children: [
          {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (id, record) => (
              <div className="flex flex-col 2xl:flex-row gap-1 column-row-block">
                <div className="flex flex-row gap-2 2xl:hidden column-title-block">
                  <div>ID: { id },</div>
                  <div>{ record.first_name }</div>
                  <div>{ record.last_name },</div>
                  <div>Ст: { record.level },</div>
                  <div>TG: { record.telegram }</div>
                </div>
                <div className="flex flex-row gap-2 2xl:hidden column-title-block">
                  <div className="grow">Всего</div>
                  <div className="w-16 text-right">{ record.pv }</div>
                  <div className="w-16 text-right">{ record.nv }</div>
                  <div className="w-16 text-right">{ record.av }</div>
                </div>
                <div className="flex flex-row gap-2 2xl:hidden column-title-block">
                  <div className="grow">За месяц</div>
                  <div className="w-16 text-right">{ record.spv }</div>
                  <div className="w-16 text-right">{ record.snv }</div>
                  <div className="w-16 text-right">{ record.sav }</div>
                </div>
                <div className="hidden 2xl:block column-content-block st-table-num column_id">{ id }</div>
              </div>
            ),
          },
          {
            title: 'Имя',
            dataIndex: 'first_name',
            key: 'first_name',
            render: (first_name) => (
              <div className="flex flex-row gap-1 column-row-block">
                <div className="block lg:hidden column-title-block">Имя:</div>
                <div className="column-content-block st-table-imfo type column_type">{ first_name }</div>
              </div>
            ),
          },
          {
            title: 'Фамилия',
            dataIndex: 'last_name',
            key: 'last_name',
            render: (last_name) => (
              <div className="flex flex-row gap-1 column-row-block">
                <div className="block lg:hidden column-title-block">Фамилия:</div>
                <div className="column-content-block st-table-imfo type column_type">{ last_name }</div>
              </div>
            ),
          },
          {
            title: 'Ст.',
            dataIndex: 'level',
            key: 'level',
            render: (level) => (
              <div className="flex flex-row gap-1 column-row-block" title="Ступень">
                <div className="block lg:hidden column-title-block">Ступень:</div>
                <div className="column-content-block st-table-imfo type column_type">{ level }</div>
              </div>
            ),
          },
          {
            title: 'TG',
            dataIndex: 'telegram',
            key: 'telegram',
            render: (telegram) => (
              <div className="flex flex-row gap-1 column-row-block" title={ `Телеграмм: ${ telegram }` }>
                <div className="block lg:hidden column-title-block">Телеграмм:</div>
                <div className="type column_tg w-10 text-ellipsis overflow-hidden whitespace-nowrap">{ telegram }</div>
              </div>
            ),
          },
        ],
      },
      {
        title: 'Текущие баллы',
        children: [
          {
            title: 'Личные',
            key: 'pv',
            dataIndex: 'pv',
            render: (sum) => (
              <div className="flex flex-row gap-1 column-row-block">
                <div className="block lg:hidden column-title-block">Л.О.всего:</div>
                <div className="column-content-block st-table-imfo volume column_volume">
                  <NumericFormat
                    value={Number(sum)}
                    displayType={'text'}
                    decimalScale={1}
                    thousandSeparator=" "
                  />
                </div>
              </div>
            ),
          },
          {
            title: 'Сетевые',
            key: 'nv',
            dataIndex: 'nv',
            render: (sum) => (
              <div className="flex flex-row gap-1 column-row-block">
                <div className="block lg:hidden column-title-block">C.О.всего:</div>
                <div className="column-content-block st-table-imfo volume column_volume">
                  <NumericFormat
                    value={Number(sum)}
                    displayType={'text'}
                    decimalScale={1}
                    thousandSeparator=" "
                  />
                </div>
              </div>
            ),
          },
          {
            title: 'Общие',
            key: 'av',
            dataIndex: 'av',
            render: (sum) => (
              <div className="flex flex-row gap-1 column-row-block">
                <div className="block lg:hidden column-title-block">О.О.всего:</div>
                <div className="column-content-block st-table-imfo volume column_volume">
                  <NumericFormat
                    value={Number(sum)}
                    displayType={'text'}
                    decimalScale={1}
                    thousandSeparator=" "
                  />
                </div>
              </div>
            ),
          },
        ],
      },
      {
        title: 'Дельта за месяц',
        children: [
          {
            title: 'Личные',
            key: 'spv',
            dataIndex: 'spv',
            render: (sum) => (
              <div className="flex flex-row gap-1 column-row-block">
                <div className="block lg:hidden column-title-block">Л.О.дельта:</div>
                <div className="column-content-block st-table-imfo volume column_volume">
                  <NumericFormat
                    value={ Number(sum) }
                    displayType={ 'text' }
                    decimalScale={ 1 }
                    thousandSeparator=" "
                  />
                </div>
              </div>
            ),
          },
          {
            title: 'Сетевые',
            key: 'snv',
            dataIndex: 'snv',
            render: (sum) => (
              <div className="flex flex-row gap-1 column-row-block">
                <div className="block lg:hidden column-title-block">C.О.дельта:</div>
                <div className="column-content-block st-table-imfo volume column_volume">
                  <NumericFormat
                    value={ Number(sum) }
                    displayType={ 'text' }
                    decimalScale={ 1 }
                    thousandSeparator=" "
                  />
                </div>
              </div>
            ),
          },
          {
            title: 'Общие',
            key: 'sav',
            dataIndex: 'sav',
            render: (sum) => (
              <div className="flex flex-row gap-1 column-row-block">
                <div className="block lg:hidden column-title-block">О.О.дельта:</div>
                <div className="column-content-block st-table-imfo volume column_volume">
                  <NumericFormat
                    value={ Number(sum) }
                    displayType={ 'text' }
                    decimalScale={ 1 }
                    thousandSeparator=" "
                  />
                </div>
              </div>
            ),
          },
        ],
      },
    ];

    return (
      <main className="report-volume-user-list-page">
        <div className="wrap">
          <MobileHeader />
          <CatalogHeader
            pageName={t('LEFT_MENU_'.concat(getRouteText(window.location.pathname)))}
            parentPageName={t('LEFT_MENU_REPORT')}
            parentPageLink={ROUTE_REPORT}
            withPaySubscription={false}
          />
          <div className="flex flex-col gap-1">
            <Form ref={this.formRef} className="static__header flex flex-col gap-4 !px-10">
              <div className="header1 flex flex-row gap-4">
                <div className="label-for-header period font-semibold">
                  Период :
                </div>
                <button onClick={(e) => this.onChangePeriod(e, -1)}>
                  <LeftSquareOutlined />
                </button>
                <input className="input-date" value={date} disabled />
                <button onClick={(e) => this.onChangePeriod(e, 1)} disabled={period >= 0}>
                  <RightSquareOutlined />
                </button>
              </div>
              <div className="header2 flex flex-row gap-4">
                <div className="label-for-header !leading-4 font-semibold">
                  Список пользователей<br />
                  в вашей структуре (ID):
                </div>
                <Form.Item name='user_list'
                           label={false}
                           // className="form__block"
                           validateTrigger={['onBlur', 'onChange']}
                           validateFirst={true}
                           rules={[
                             {pattern: /^(\d+,?)*$/, message: 'Разрешены только числа и одна запятая между ними'},
                             {validateTrigger: 'onBlur', pattern: /\d+$/, message: 'Список должен заканчиваться числом, а не запятой'},
                           ]}
                >
                  <Input
                    placeholder='1,2,30,43,...'
                    autoComplete='off'
                    name='user_list'
                    id='user_list'
                    onChange={this.onChangeUserList}
                  />
                </Form.Item>
                <div className="align-middle">
                  {/*<button onClick={(e) => this.onSaveUserList(e)}>
                    <SaveOutlined />
                  </button>*/}
                  <SaveOutlined
                    className="text-[22px] !leading-[22px] block relative"
                    onClick={(e) => this.onSaveUserList(e)}
                  />
                </div>
              </div>
            </Form>
            <div className="static__contents !py-4">
              <div className="static__cont">
                <Table
                  dataSource={userListVolumes}
                  columns={columns}
                  loading={loading}
                  rowKey={(record) => record.id}
                  rowClassName="st-table-line flex-col lg:flex-row !items-start lg:items-center w-full gap-1 lg:gap-0"
                  pagination={false}
                  className='static-table'
                  bordered
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
  userFullInfo: state.user,
});

export default connect(mapStateToProps)(
  withTranslation()(
    withRouter(
      ReportVolumeUserListPage
    )
  )
);
