import FormWithMessage from '@common/formWithMessage';
import api from '@services/api';
import { getMessageFromRequest } from '@src/helpers';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Spin } from 'antd';

import { closeDrawer } from '@state/drawer';

import { ReactComponent as CloseIcon } from '@ui/icons/close.svg';

import '@routes/login/styles.less';

// const { Dragger } = Upload;
const { TextArea } = Input;

class FeedBack extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    isError: false,
    isSuccess: false,
    textMessage: undefined,
    fileList: [],
  }

  handleSubmit = (val) => {
    const { location, userInfo } = this.props;

    this.formRef.current.validateFields().then(async (values) => {
      this.setState({ loading: true });

      const { status, data } = await api.sendFeedback({
        location: location,
        // location: JSON.stringify(location),
        user_id: userInfo?.id,
        ...values,
        // files: values?.files?.fileList,
      });

      this.setState({
        loading: false,
        textMessage: getMessageFromRequest(data),
      });

      if (status === 200) {
        this.setState({
          isSuccess: data?.status === 0,
          isError: data?.status !== 0,
        })
      } else {
        this.setState({
          isError: true,
        })
      }
    });
  };

  render() {
    const {userInfo, setCloseDrawer} = this.props;
    const {
      loading,
      isError,
      isSuccess,
      textMessage,
      // fileList,
    } = this.state;

    /*const props = {
      name: 'files',
      multiple: true,
      maxCount: 5,
      // listType: 'picture',
      accept: [
        '.pdf',
        '.doc',
        '.docx',
        '.txt',
        '.xls',
        '.xlsx',
        'image/!*',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/rtf',
        'text/plain',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ].join(','),
      // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
      // beforeUpload: (file) => {
      //   const isApply = [
      //     'image/png',
      //   ].includes(file.type);
      //   if (!isApply) {
      //     message.error(`${file.name} - неподходящий тип файла`);
      //   }
      //   return isApply || Upload.LIST_IGNORE;
      // },
      onRemove: (file) => {
        this.setState(state => {
          const { fileList } = state;
          const index = fileList.indexOf(file);
          const newFileList = fileList.slice();
          newFileList.splice(index, 1);
          return { ...state, fileList: newFileList };
        })
      },
      beforeUpload: (file) => {
        this.setState(state => ({
          ...state,
          fileList: [
            ...state.fileList,
            file,
          ],
        }));
        return false;
      },
      fileList,
      // onChange(info) {
      //   const { status } = info.file;
      //   if (status !== 'uploading') {
      //     console.log(info.file, info.fileList);
      //   }
      //   if (status === 'done') {
      //     message.success(`${info.file.name} file uploaded successfully.`);
      //   } else if (status === 'error') {
      //     message.error(`${info.file.name} file upload failed.`);
      //   }
      // },
      onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
      },
    };*/

    return (
      <div id="modal4" className="modal4 feedback-modal modal" key={userInfo?.id}>
        <Spin spinning={loading}>
          <div className="modal__block-close">
            <CloseIcon onClick={setCloseDrawer}/>
          </div>
          <div className="modal__window">
            <div className="modal__head">
              <h1>Обращение в службу поддержки</h1>
            </div>
            <FormWithMessage
              setFormType={() => {}}
              isError={isError}
              isSuccess={isSuccess}
              textMessage={textMessage}
              onClickButtonGoHome={() => setCloseDrawer()}
            >
              <Form
                name='FeedBackForm'
                ref={this.formRef}
                layout='vertical'
                initialValues={{
                  name: `${userInfo.first_name} ${userInfo.last_name}`,
                  email: userInfo.email,
                  phone: userInfo.phone,
                }}
                onFinish={this.handleSubmit}
              >
                <div className="form__content">
                  <Form.Item name='name' label="Имя, Фамилия" className="form__block"
                             validateTrigger={['onBlur', 'onChange']}
                             validateFirst={true}
                             rules={[
                               {required: true, message: 'Пожалуйста, введите своё имя'},
                               {max: 100, message: 'Максимальная длина поля - 100 символов'},
                             ]}>
                    <Input placeholder='Ivan Ivanov' disabled={!!userInfo.id} />
                  </Form.Item>
                  <Form.Item name='email' label="Email" className="form__block"
                             tooltip={'Пожалуйста, укажите действительный адрес электронной почты, т.к. на него придет письмо с ответом по вашему обращению'}
                             validateTrigger={['onBlur', 'onChange']}
                             validateFirst={true}
                             rules={[
                               {required: true, message: 'Пожалуйста, введите адрес своей электронной почты'},
                               {validateTrigger: 'onBlur', min: 6, message: 'Минимальная длина поля - 6 символов'},
                               {max: 200, message: 'Максимальная длина поля - 100 символов'},
                               {pattern: /[@a-zA-Z0-9_.–—‐-]+/, message: 'В поле можно вводить только латинские буквы, цифры, точку, дефис и знак подчеркивания'},
                               {validateTrigger: 'onBlur', type: 'email', message: 'Пожалуйста, введите корректный адрес электронной почты'},
                             ]}>
                    <Input placeholder='nickName@example.com' disabled={!!userInfo.id} />
                  </Form.Item>
                  <Form.Item name='phone' label="Номер телефона" className="form__block"
                             validateTrigger={['onBlur', 'onChange']}
                             validateFirst={true}
                             rules={[
                               {required: true, message: 'Пожалуйста, введите свой номер телефона'},
                               {
                                 transform: (x) => x.replace(/[^+\d]/, '').replace(/[() -]/g, ''),
                                 max: 15,
                                 message: 'Максимально можно ввести 15 цифр, включая код страны и знак "плюс"'
                               },
                               {pattern: /^\+/, message: 'Первым должен идти знак "плюс" и код страны (+7 для России)'},
                               {
                                 pattern: /^[+\d() -]*/,
                                 message: 'В этом поле можно вводить только цифры, круглые скобки, дефисы, пробелы и код страны (+7 для России)'
                               },
                             ]}>
                    <Input placeholder='+7 (999) 999-99-99' disabled={!!userInfo.id} />
                  </Form.Item>
                  <Form.Item name='subject' label="Краткая тема обращения" className="form__block"
                             validateTrigger={['onBlur', 'onChange']}
                             validateFirst={true}
                             rules={[
                               {required: true, message: 'Пожалуйста, введите краткую тему обращения'},
                               {
                                 // validateTrigger: 'onBlur',
                                 max: 100,
                                 message: 'Максимальная длина поля - 100 символов'
                               },
                             ]}>
                    <Input placeholder='Ошибка на стадии регистрации' />
                  </Form.Item>
                  <Form.Item name='text' label="Описание" className="form__block"
                             validateTrigger={['onBlur', 'onChange']}
                             validateFirst={true}
                             rules={[
                               {required: true, message: 'Пожалуйста, введите подробное описание'},
                               {
                                 // validateTrigger: 'onBlur',
                                 max: 2000,
                                 message: 'Максимальная длина поля - 2000 символов'
                               },
                             ]}>
                    <TextArea placeholder='Подробное описание' rows={4} showCount={true} maxLength={2000} />
                  </Form.Item>
                  {/*<Form.Item name='files' label="Фотографии/скриншоты ошибки или документа" className="form__block"
                             validateTrigger={['onBlur', 'onChange']}
                             validateFirst={true}
                             rules={[
                               {
                                 validator(_, value) {
                                   return value?.fileList?.length > 5
                                     ? Promise.reject('Разрешено максимально 5 файлов')
                                     : Promise.resolve();
                                 }
                               },
                               {
                                 validator(_, value) {
                                   return (
                                     value?.fileList?.length
                                     && value?.fileList?.reduce((memo, file) => memo + (file?.size || 0), 0) > (50 * 1024 * 1024)
                                       ? Promise.reject('Превышен максимальный общий размер файлов в 50 Mb')
                                       : Promise.resolve()
                                   );
                                 }
                               },
                             ]}
                  >
                    <Dragger {...props}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Для загрузки файлов, нажмите <u>тут</u><br /> или перенесите файлы на эту область</p>
                      <p className="ant-upload-hint">
                        Можно загрузить до 5 файлов.<br />
                        Максимальный общий объем - 50 Mb<br />
                        Разрешенные типы: графические, pdf, dоc, dоcx, txt, xls, xlsx.
                      </p>
                    </Dragger>
                  </Form.Item>*/}
                </div>
                <button className="standart-btn">
                  <div>Сохранить</div>
                </button>
              </Form>
            </FormWithMessage>
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
  drawer: state.drawer,
});

const mapDispatchToProps = {
  setCloseDrawer: closeDrawer,
}

export default connect(mapStateToProps, mapDispatchToProps)(
  FeedBack
);
