import api from '@services/api';
import storage from '@services/storage';
import {
  APPSTAGE_AUTH,
  AUTH_COOKIE_NAME,
  DISABLE_DOUBLE_AUTH,
  FORM_REGISTRATION,
  FORM_RESET_PASSWORD,
  FORM_VERIFY_EMAIL_REPEAT,
} from '@src/constants';
import { getMessageFromRequest } from '@src/helpers';
import { setAppStage } from '@state/appStage';
import { closeModal, setModalContent } from '@state/modal';
import { useAppDispatch } from '@state/store';
import { setGaVisible, setUserExtraInfo } from '@state/user';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Input, message, Spin, } from 'antd';

const LoginForm = ({ setFormType, formRef, setAppStageData, setUserInfo, setGoogleAuth, t }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  /** Особое уведомление при входе на сайт - максимальный срок отображения */
  const maxDate = new dayjs('2023-02-25');

  /** Особое уведомление при входе на сайт - текст уведомления */
  const textMessage = useMemo(() => (
    <>
      <p>
        Уважаемые партнеры, информируем вас, что в связи с недавним обновлением в сети TRON,
        комиссии за совершаемые транзакции выросли вдвое. Ввиду чего, руководством SoulMate
        company было принято решение увеличить комиссионные сборы при вводе/выводе ваших средств
        до 4 USDT за одну транзакцию.</p>
      <br />
      <p>P.S. Все подробности об обновлении сети Tron можно найти здесь&nbsp;-&nbsp;
        <a href={'https://tronscan.org/#/proposal/83'} target='_blank' rel="noreferrer" style={{ color: '#40a9ff' }}>
          https://tronscan.org/#/proposal/83
        </a>
      </p>
    </>
  ), []);

  /** Особое уведомление при входе на сайт - функция отображения */
  const openNotification = () => {
    dispatch(setModalContent({
      title: 'Изменение комиссии',
      content: textMessage,
      params: {
        onlyOk: true,
        onlyOkText: 'Ок',
        onOk: () => {
          dispatch(closeModal());
        }
      },
    }));
  }

  const handleRegistration = () => {
    setFormType(FORM_REGISTRATION);
    return false;
  }

  const handleResetPassword = () => {
    setFormType(FORM_RESET_PASSWORD);
    return false;
  }

  const handleVerifyEmailRepeat = () => {
    setFormType(FORM_VERIFY_EMAIL_REPEAT);
    return false;
  }

  const afterLogin = async (token, userInfo) => {
    storage.set(AUTH_COOKIE_NAME, token);
    await api.setAUTHToken(token);
    await setUserInfo(userInfo);
    await setAppStageData(APPSTAGE_AUTH);
    /** Особое уведомление при входе на сайт */
    if ((new dayjs()) < maxDate) {
      setTimeout(() => openNotification(), 600);
    }
  }

  const handleSubmit = (val) => {
    formRef.current.validateFields().then(async (values) => {
      setLoading(true);

      const { status, data } = await api.login(values.email, values.password);

      setLoading(false);

      if (status === 200 && data?.status === 20000) {
        setFormType();
        setGoogleAuth({
          visible: true,
          loginToken: data?.auth_token,
          procedure: "авторизации пользователя",
          callBackLogin: async (token, userInfo) => {
            // Предотвращение двойной 2-х-факторной аутентификации
            storage.set(DISABLE_DOUBLE_AUTH, 1);
            setTimeout(() => storage.remove(DISABLE_DOUBLE_AUTH), 5 * 1000);

            await afterLogin(token, userInfo);
          },
        });
      } else if (status === 200 && data?.status === 0) {
        await afterLogin(data?.token, data?.user);
      } else {
        message.error(
          getMessageFromRequest(data, 'Ошибка входа. Проверьте правильность электронной почты и пароля'),
          5
        );
      }
    });
  };

  return (
    <Form
      name='LoginForm'
      ref={formRef}
      layout='vertical'
      // initialValues={{ remember: true }}
      onFinish={handleSubmit}
      // className={styles.content}
    >
      <Spin spinning={loading}>
        <div className="form__content">
          <Form.Item name='email' label="Email" className="form__block"
                     validateTrigger={['onBlur', 'onChange']}
                     validateFirst={true}
                     rules={[
                       {required: true, message: 'Пожалуйста, введите адрес своей электронной почты'},
                       {validateTrigger: 'onBlur', min: 6, message: 'Минимальная длина поля - 6 символов'},
                       {max: 200, message: 'Максимальная длина поля - 100 символов'},
                       {pattern: /[@a-zA-Z0-9_.–—‐-]+/, message: 'В поле можно вводить только латинские буквы, цифры, точку, дефис и знак подчеркивания'},
                       {validateTrigger: 'onBlur', type: 'email', message: 'Пожалуйста, введите корректный адрес электронной почты'},
                     ]}>
            <Input placeholder='nickName@example.com'/>
          </Form.Item>
          <Form.Item name='password' label="Пароль" className="form__block"
                     validateTrigger={['onBlur', 'onChange']}
                     rules={[
                       {required: true, message: 'Пожалуйста, введите пароль'},
                       {validateTrigger: 'onBlur', min: 7, message: 'Пароль может быть минимум 7 символов'},
                     ]}
          >
            <Input.Password />
          </Form.Item>
        </div>
        <p>
          <button type='button' className="form__info" onClick={handleResetPassword}>
            Забыл пароль
          </button>
        </p>
        <button type='submit' className="standart-btn">
          <div>Войти</div>
        </button>
        <span className="form__next">
          У вас нет аккаунта?
          &nbsp;
          <button type='button' onClick={handleRegistration}>
            Зарегистрируйтесь
          </button>
        </span>
        <br />
        <span className="form__next">
          Не пришло письмо после регистрации?
          &nbsp;
          <button type='button' onClick={handleVerifyEmailRepeat}>
            Повторить
          </button>
        </span>
      </Spin>
    </Form>
  );
}

const mapDispatchToProps = {
  setAppStageData: setAppStage,
  setUserInfo: setUserExtraInfo,
  setGoogleAuth: setGaVisible,
};

export default connect(null, mapDispatchToProps)(
  withTranslation()(
    LoginForm
  )
);
